@import "~@/assets/sass/_function";
@import "~@wtdc/img/sass/img.scss";

$imgPrefix: "wtdc-img";

.#{$imgPrefix} {
  .loading {
    width: 100%;
    height: 100%;
    color: rgba(black, 0.2);
    svg {
      width: 90%;
      height: 80%;
      max-width: 105px;
      max-height: 70px;
    }
  }

  .load-error {
    width: 100%;
    height: 100%;
    color: rgba(black, 0.2);
    svg {
      width: 50%;
      height: 50%;
      max-width: 60px;
      max-height: 60px;
    }
  }

  .load-error,
  .loading {
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &-aspect {
    .loading,
    .load-error {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &:not(.#{$imgPrefix}-aspect) {
    .loading,
    .load-error {
      min-height: 60px;
    }
  }
}
.WTDCImg-EkIN2Gdd {
  flex-shrink: 0;
}

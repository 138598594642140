@import "~@/assets/sass/_function";

.LiveCodeGroup {
  padding: 0 20px;
  margin-bottom: 14px;
  .group-label {
    text-align: right;
  }
  .group-list {
    flex-wrap: wrap;
  }
  .group-item {
    display: flex;
    justify-items: center;
    justify-content: space-between;
    position: relative;
    margin: 10px;
    padding: 6px 20px;
    min-width: 120px;
    border: 1px solid $grayD;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;

    &-name {
      flex: 1;
      text-align: center;
      @include ellipsis-lines(1);
    }

    &-active {
      border-color: $colorPrimary;
    }

    .delete-icon,
    .edit-icon {
      font-size: 16px;
      color: $colorPrimary;
      background-color: $white;
      line-height: 1;

      &.delete-icon {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
      }
      &.edit-icon {
        transform: translate(50%, 10%);
      }
    }
  }
  .group-add {
    color: $colorPrimary;
    border: 1px dashed $colorPrimary;
  }
}

.userGroup_JW-JKJqq {
  .modal-content {
    padding: 60px 40px 70px;
    .wtdc-form-item {
      display: flex;
      align-items: center;
      &-children-wrapper {
        flex: 1;
      }
    }
  }
}

@import "~@/assets/sass/_function";

//@import "~@wtdc/date-picker/sass/date-picker";

$prefix-cls: "ant-calendar";
$timepicker-prefix-cls: "#{$prefix-cls}-time-picker";
$item-hover-bg: rgba($primary-color, 0.1);
$text-color: rgba(#000, 0.65);
$disabled-color: rgba(#000, 0.25);
$disabled-bg: #f5f5f5;
$input-box-height: 40px;

* {
  &:before,
  &:after {
    box-sizing: border-box;
  }

  .#{$timepicker-prefix-cls} {
    &-select li:last-child {
      margin-bottom: 200px;
      &:after {
        height: 0;
      }
    }
  }
}

.ant-picker-range {
  display: inline-flex !important;
  .ant-picker-active-bar {
    display: none;
  }
}

.ant-date-footer {
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.ant-date-main-panel {
  display: flex;
  .admp-quick {
    width: 106px;
    padding: 13px 0 0 20px;
    border-right: 1px solid #ddd;
    .admpq-name {
      line-height: 19px;
      font-size: 14px;
      margin-bottom: 16px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .admp-date {
    flex: 1;
  }
}

@import "~@/assets/sass/_function";
@import "~@wtdc/checkbox/sass/checkbox.scss";

$checkboxPrefixCls: "wtdc-checkbox";

.#{$checkboxPrefixCls} {
  &-title {
    margin-left: 10px !important;
    font-size: 15px;
  }

  &-group {
    .#{$checkboxPrefixCls} {
      margin-right: 30px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

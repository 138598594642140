@import "~@/assets/sass/_function";

.TemplateMakeContent_NEvdQT99 {
  & > .step {
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    &.one-weo {
      .member-img,
      .wtdc-img-transparent-mask {
        width: 48px;
        height: 48px;
        opacity: 1;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 10px 0 0;
      }
    }

    &.one-step {
      .invite-title {
        position: relative;
        height: 20px;
      }
      .BookLabel {
        height: 32px;
      }
      .invite-wrapper {
        display: flex;
        flex-direction: row;
      }
      .invite-content {
        margin-left: 50px;
        .wtdc-form-item {
          display: flex;
          flex-direction: column;
        }
        .wtdc-form-item-label {
          min-width: 70px;
        }
        .EmojiEditor {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }

    &.two-step {
      flex-direction: row;

      &.mpa-hide {
        display: none !important;
      }
    }

    .wtdc-form-item {
      display: flex;

      .member-list {
        display: flex;
        flex-wrap: wrap;
        width: 700px;
        align-items: center;

        .modify-btn {
          color: #4e77b8;
          font-size: 15px;
          cursor: pointer;
        }
      }

      .single-code-info {
        display: flex;
        align-items: center;
        margin: 0 30px 0 0;
      }
    }

    .title {
      margin: 0 0 30px 0;
      font-size: 20px;
      font-weight: bold;
      line-height: 34px;
      color: #3a3446;
    }

    .wtdc-form-item-label {
      min-width: 120px;
    }

    .sop-categroy {
      .wtdc-select-selection-placeholder {
        height: 36px;
        line-height: 36px;
      }
    }

    .AddButton {
      height: 36px;
      line-height: 34px;

      > i {
        height: 34px;
        line-height: 38px;
      }
    }

    .next-btn {
      height: 36px;
      line-height: 36px;
      margin: 0 0 0 120px;
    }

    &.two-step {
      .wtdc-form-item-label.wtdc-form-item-required {
        min-width: 120px;
        line-height: 1em;
      }

      .left-warp {
        width: 786px;
      }

      .right-warp {
        .sub-title {
          font-size: 20px;
          line-height: 24px;
          color: #333333;
        }

        .preview {
          width: 322px;
          height: 600px;
          background: url("https://file.5rs.me/oss/uploadfe/svg/7d705c207038905899838099f0327fc9.svg") center no-repeat;
          background-size: cover;
          margin: 23px 0 0 -14px;
        }
      }

      .ml80 {
        margin: 30px 0 0 80px;
      }
    }
  }

  .f-r-fs-fs {
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #welcome-message-preview {
    // padding: 144px 0 0 44px;
    padding: 106px 30px 114px 32px;
    overflow: hidden;

    #welcome-message-preview-content {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      background: #f4f4f4;
      padding: 0 0 0 12px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background-color: #d5d5d5;
        cursor: pointer;
      }

      .time {
        color: #666666;
        text-align: center;
        padding: 10px 0;
      }
    }

    .avatar-box {
      width: 34px;
      height: 34px;
      background: #4e77b8;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px 0 0;

      .img {
        width: 18px;
        height: 20px;
        background: url("https://file.5rs.me/oss/uploadfe/png/c01f358be8e7fcb1f5a064de9746760b.png") center no-repeat;
        background-size: cover;
      }
    }

    .content-item {
      display: flex;
      align-items: flex-start;
      margin: 0 0 30px;

      &.text-item {
        .text-msg {
          width: 191px;
          padding: 9px;
          border-radius: 2px;
          background-color: #ffffff;
          position: relative;
          overflow: hidden;

          &::after {
            position: absolute;
            left: -5px;
            top: 12px;
            content: "";
            border-right: 5px solid #ffffff;
            border-top: 5px dashed transparent;
            border-bottom: 5px dashed transparent;
          }
        }
      }

      &.image-item {
        .preview-img {
          width: 110px;
        }
      }

      &.link-item {
        .link-app {
          width: 191px;
          padding: 9px;
          border-radius: 2px;
          background-color: #ffffff;
          position: relative;

          &::after {
            position: absolute;
            left: -5px;
            top: 12px;
            content: "";
            border-right: 5px solid #ffffff;
            border-top: 5px dashed transparent;
            border-bottom: 5px dashed transparent;
          }

          .app-title {
            width: 100%;
            max-height: 63px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
            white-space: pre-line;
          }

          .link-app-content {
            position: relative;
            height: 40px;

            p {
              width: calc(100% - 50px);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
              white-space: pre-line;
              font-size: 12px;
              color: #999;
              margin-right: 10px;
            }

            .Img {
              position: absolute;
              right: 0;
              bottom: 0;
              border-radius: 2px;
              border: 1px solid hsla(0, 0%, 87%, 0.5);
            }
          }
        }
      }
    }
  }
}

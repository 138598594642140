@import "~@/assets/sass/_function";

.TrailLayers-b_b_M0_ {
  .modal-content {
    height: 500px;
    padding-bottom: 54px;
    .wtdc-form-item-label {
      display: inline-flex;
      width: 56px;
      margin-right: 25px;
      padding: 0;
      text-align: right;
      font-size: 14px;
      color: #3a3346;

      &::after {
        display: none;
      }
      &-text {
        width: 100%;
      }
    }
  }
  .select-user {
    &-title {
      color: #3a3346;
    }
  }
  .select-list {
    flex: 1;
    &-count {
      color: #80798d;
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      margin: 5px 0 15px 0;
    }
    &-item {
      display: flex;
      align-items: center;
      min-width: 230px;
      width: 30%;
      margin: 5px;
      padding: 15px;
      border-radius: 4px;
      background: rgba(63, 81, 181, 0.06);
      .wtdc-img-inner {
        object-fit: scale-down;
      }
      .item-name {
        @include ellipsis-lines(2);
      }
    }
  }
}

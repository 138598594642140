@import "~@/assets/sass/_function";

.CommunityLayers-wz7EUNE {
  .modal-content {
    padding: 30px;
    .community-title {
      flex-shrink: 0;
    }
    .community-list {
      display: flex;
      flex-wrap: wrap;
    }
    .community-item {
      width: 45%;
      padding: 16px 15px;
      margin: 10px 15px;
      background: rgba(63, 81, 181, 0.06);
      border-radius: 4px;
      &-head {
        align-items: center;
        .group-name {
          @include ellipsis-lines(2);
        }
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        margin-right: 10px;
        background: #3f51b5;
        border-radius: 4px;
        .ic-shequn {
          color: $white;
          font-size: 25px;
        }
      }
      &-info {
        padding: 10px 12px;
        margin-top: 10px;
        border-radius: 4px;
        background: $white;
      }
    }
  }
}

@import "~@/assets/sass/_function";
@import "~@wtdc/form/sass/form";
@import "~@wtdc/icon/sass/icon.scss";

$formPrefixCls: "wtdc-form";

.#{$formPrefixCls} {
  &-item {
    margin-bottom: 20px;
    &-required {
      .wtdc-form-item-label-text {
        &:before {
          content: "" !important;
          display: none;
        }
        &:after {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          content: "*";
          font-size: 15px;
          line-height: 1;
          color: #ff3668;
        }
      }
    }
    &-label {
      font-size: 15px;
      padding-right: 20px;
      text-align: left;
      padding-left: 0 !important;
    }
    &-children-wrapper {
      display: inline-block;
      // <Form.Item type="search" />
      .wtdc-input-addon {
        &-after {
          .wtdc-btn.wtdc-input-search-button {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &-error-message {
      color: $warning-color;
    }

    .wtdc-select-selector {
      border-radius: 4px;
    }
  }

  .wtdc-radio-group,
  .wtdc-checkbox-group {
    line-height: 31px;
  }

  // 行内布局
  &-inline-layout {
    .#{$formPrefixCls}-item {
      margin-right: 30px;
      &:last-of-type {
        margin-right: 10px;
      }
    }

    .wtdc-input-search {
      display: inline-table !important;
    }
  }

  &-item.#{$formPrefixCls}-item-has-error.#{$formPrefixCls}-item-touched {
    // 防止Form.Item内的Form.Item被错误高亮
    & > .wtdc-form-item-children-wrapper > .wtdc-input {
      .wtdc-input-affix-wrapper {
        border-color: $warning-color;
      }
    }
    & > .wtdc-form-item-children-wrapper > .wtdc-select {
      .wtdc-select-selector {
        border-color: $warning-color;
      }
    }
    & > .wtdc-form-item-children-wrapper > .ImagePicker {
      .image-picker-block {
        border-color: $warning-color;
      }
    }
    & > .wtdc-form-item-children-wrapper > .wtdc-input {
      .wtdc-input-affix-wrapper {
        border-color: $warning-color;
      }
    }

    // .wtdc-input-affix-wrapper,
    // .wtdc-select-selector,
    // .image-picker-block,
    // .edui-container {
    //   border-color: $warning-color;
    // }
  }
}

// 模态框内的表单样式
.wtdc-modal {
  .#{$formPrefixCls} {
    &-item {
      &-label {
        padding-right: 10px;
      }

      &:last-of-type {
        margin-bottom: 0;

        &.#{$formPrefixCls}-item-has-error.#{$formPrefixCls}-item-touched {
          margin-bottom: -25px;
        }
      }
    }
  }
}

// 搜索栏中的表单样式
.SearchBar {
  .#{$formPrefixCls} {
    &-item-label {
      padding-left: 0;
      padding-right: 10px;
    }
  }
}

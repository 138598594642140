@import "~@/assets/sass/_function";

.wtdc-antd-table {
  position: relative;
  padding-right: 7px;
  .position-relative {
    position: relative;
  }
  .batch-operation {
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  .ant-table-cell {
    &::before {
      display: none;
    }
  }

  .ant-table-row-cell-break-word {
    padding: 16px 30px;
  }

  .ant-table-body {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #9c9c9a !important;
    }
  }

  .ant-table-body-inner {
    // overflow: auto !important;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      box-sizing: border-box;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #9c9c9a !important;
    }
  }
  .ant-table-body-inner:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }
  }

  // btn
  .table-operation-btn-group {
    min-width: 110px;
    .table-operation-btn {
      position: relative;
      color: $primary-color;
      background: none;
      border: none;
      outline: none;
      font-size: 14px;
      padding: 0 6px;
      cursor: pointer;
      transition: all 0.3s;

      > .Link {
        color: $primary-color;

        &:hover {
          color: darken($primary-color, 8%);
        }
      }

      &:hover {
        color: darken($primary-color, 8%);
      }

      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 1px;
        height: 16px;
        background-color: $primary-color;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      &:last-of-type:after {
        content: none;
      }
    }
  }
}
.erp-content_scroll-view:hover {
  .ant-table-body {
    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }
  }
  .ant-table-body-inner {
    &::-webkit-scrollbar-thumb {
      background-color: #dedede;
    }
  }
}

.wtdc-antd-table-seletor-total {
  height: 40px;
  padding-top: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  color: $colorPrimary;
}

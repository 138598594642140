@charset "UTF-8";

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$font-size: 14px !default;
$pad-font-size: 12px !default;
$font-line-height: 1.5 !default;
$font-family: San Francisco UI, Helvetica Neue, Arial, DIN Alternate, PingFang SC, Hiragino Sans GB, Micrisoft YaHei !default;

// color
$primary-color: #4e50b8 !default; // 主色
$secondary-color: #2e2f8e !default; // 辅色
$contrast-color: #ff7295 !default; // 对比色
$warning-color: #ff3668 !default; // 警示颜色
$colorWarning: #ff3668 !default; //对比色
$info-color: #598ae3 !default; // 蓝色
$tips-color: #ffa246 !default; //提示颜色
$link-color: #666 $primary-color !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$bg-color: #fff !default;
$placeholder-color: #b3b0ba !default;
$white: white !default;
$black: black !default;
$colorPrimary: $primary-color; // for wtdc components
$colorPrimaryLight: $primary-color; // for wtdc components

// text color
$text-color: #333 !default;
$text-color-dark: #3a3446 !default;
$text-color-light: #b3b0b9 !default;
$text-color-gray: #999 !default;

$text-color-8: rgba($text-color-dark, 0.8);
$text-color-6: rgba($text-color-dark, 0.6);
$text-color-2: rgba($text-color-dark, 0.2);

// border color
$border-color: #ddd !default;

// bg color
$page-gray-bg: #f2f2f5 !default;

// media
$tablet-width: 1024px;
$laptop-width: 1280px;
$desktop-width: 1920px;
$marginBase: 20px !default;

//px
$page-header-height: 60px;
$sidebar-width: 150px;

//btn
$btn-height: 32px;

$private-domain-color: #4e50b8;
//企业微信
$wx-primary-color:  #4e50b8 !default; // 主色

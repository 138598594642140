@import "~@/assets/sass/_function";
@font-face {
  font-family: YouSheBiaoTiHei;
  src: url(https://cdn.5rs.me/font/YouSheBiaoTiHei-2.ttf);
}
.PreviewPhoneWrap {
  .preview-title {
    width: 356px;
    text-align: center;
    margin-bottom: 5px;
  }
  .preview-wrap {
    transform: scale(0.78);
    transform-origin: top left;
    width: 455px;
    height: 800px;
    background: center center / 100% 100% no-repeat;
    background-image: url(https://oss.5rs.me/oss/uploadfe/png/9202b93c815ca3a0627a38a52ab881e8.png);
    padding: 140px 34px;
    .PreviewPhone {
      height: 564px;
      position: relative;
      .title {
        position: absolute;
        top: -30px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
      }
      > div {
        width: 100%;
        height: 100%;
      }
      .remark-bottom {
        height: 21px;
        font-size: 18px;
        font-family: YouSheBiaoTiHei;
        color: #ff7734;
        line-height: 21px;
        margin-top: 18px;
      }
      .specialArea {
        height: 72px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 20px;
      }
      .footer-withUserList {
        padding-right: 0 !important;
        .user-list {
          width: 100%;
          height: 280px;
          overflow-y: auto;
          padding-top: 20px;
          &::-webkit-scrollbar {
            width: 2px;
            height: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.4);
          }
          &-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            line-height: 28px;

            .avatar {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              margin-right: 10px;
            }

            .username {
              display: flex;
              flex: 1;
              overflow: hidden;
              margin-right: 20px;

              .name {
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .PreviewPhone_YELLOW {
        background: linear-gradient(180deg, #fdd370 0%, #f4d06f 100%);
        padding: 25px 10px 20px 10px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .bg {
          width: 100%;
          background: #ffebae;
          border-radius: 20px;
          padding: 30px 15px 15px 15px;
          position: relative;
          .topLeft {
            position: absolute;
            left: 0;
            top: -25px;
            width: 60px;
            height: 50px;
          }
          .content {
            padding: 20px 10px 15px 10px;
            width: 100%;
            background-color: #fff;
            border-radius: 16px 16px 0 0;
            .btnGroup {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-bottom: 10px;
              .btnItem {
                width: 30%;
                height: 36px;
                margin-right: 5%;
                border-radius: 18px;
                line-height: 36px;
                background: #fef8df;
                font-size: 13px;
                margin-bottom: 16px;
                text-align: center;
                font-weight: 600;
                color: #453c35;
                cursor: pointer;
              }
              .activeBtn {
                color: #ffffff;
                background: linear-gradient(90deg, #ffa049 0%, #fe7131 100%);
              }
            }
            .QRCode {
              width: calc(100% - 12px);
              background: linear-gradient(180deg, #fef7db 0%, #ffffff 100%);
              border-radius: 9px;
              text-align: center;
              padding: 13px 10px 10px 10px;
              margin: 0 6px;
              .remark {
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #453c35;
                line-height: 20px;
              }
              .img {
                width: 210px;
                height: 210px;
                background: #ffffff;
                border-radius: 16px;
                margin: 13px auto 0 auto;
                padding: 10px;
                img {
                  width: 190px;
                  height: 190px;
                }
              }
              .hint {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #b95112;
                line-height: 17px;
                margin-top: -7px;
              }
              .remark-bottom {
                color: #ff7734;
              }
            }
          }
          .footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 20px;
            background-color: #fff;
            border-radius: 0 0 16px 16px;
            .count {
              height: 27px;
              line-height: 27px;
              background: linear-gradient(90deg, #ffa049 0%, #fe7131 100%);
              border-radius: 7px;
              display: inline-block;
              padding: 0 13px;
              margin-bottom: 20px;
              font-weight: 500;
              color: #ffffff;
            }
            .userHead {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 17px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 30px;
                height: 30px;
              }
            }
            .user-list {
              padding-right: 10px;
            }
          }
        }

        &_withUserList {
          .contentBg .content {
            border-radius: 16px;
          }
          .footerBg {
            margin-top: 20px;
            .footer {
              border-radius: 16px;
            }
            .count {
              margin-top: -14px;
            }
          }
        }
      }
      .PreviewPhone_GREEN {
        background-image: url("https://file.5rs.me/oss/uploadfe/png/29b8adf50a8d9c35627f91c0fe7f4790.png");
        background-size: 100% 100%;
        padding: 15px 10px 20px 10px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .bg {
          position: relative;
          width: 100%;
          .contentBg {
            width: 100%;
            background: #ffd600;
            border-radius: 6px;
            padding: 12px 16px;
            border: 1px solid #060807;
          }
          .content {
            padding: 20px 10px 15px 10px;
            width: 100%;
            background-color: #fff;
            border: 1px solid #060807;
            position: relative;
            .btnGroup {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              .btnItem {
                width: 30%;
                height: 36px;
                margin-right: 5%;
                border-radius: 6px;
                line-height: 36px;
                background: #fbedc7;
                font-size: 13px;
                margin-bottom: 16px;
                text-align: center;
                font-weight: 600;
                color: rgba(52, 2, 2, 0.4);
                cursor: pointer;
              }
              .activeBtn {
                background: #060807;
                border-radius: 6px;
                div {
                  background: #ffb102;
                  border: 1px solid #060807;
                  color: #340202;
                  border-radius: 3px;
                  height: 100%;
                  line-height: 31px;
                  margin: -1px 1px 1px -1px;
                }
              }
            }
            .QRCode {
              width: 100%;
              text-align: center;
              .remark {
                height: 36px;
                background: #fbedc7;
                border-radius: 6px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 36px;
                margin-bottom: 18px;
                margin-top: 10px;
              }
              .img {
                padding-bottom: 8px;
                img {
                  width: 190px;
                  height: 190px;
                }
              }
              .hint {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 17px;
              }
              .remark-bottom {
                color: #ffb102;
              }
              .specialArea {
                margin-bottom: 25px;
              }
            }
          }
          .footerBg {
            background: #ffd600;
            border-radius: 6px;
            border: 1px solid #060807;
            margin-top: 20px;
            position: relative;
            padding: 12px 16px;
          }
          .footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            padding: 30px 8px 10px 8px;
            position: relative;
            border: 1px solid #060807;
            .line {
              display: inline-block;
              position: absolute;
              width: 6px;
              height: 34px;
              background: #ffd600;
              border-radius: 8px;
              border: 1px solid #060807;
              top: -40px;
              left: 50px;
            }
            .line-right {
              right: 50px;
              left: unset;
            }
            .count {
              height: 32px;
              line-height: 32px;
              background: #fbedc7;
              border-radius: 16px;
              border: 1px solid #060807;
              text-align: center;
              padding: 0 15px;
              display: inline-block;
              position: absolute;
              left: 50%;
              top: -20px;
              transform: translate(-50%, 0);
              word-break: keep-all;
              font-size: 12px;
              font-weight: 500;
              color: #030303;
            }
            .userHead {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 17px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 30px;
                height: 30px;
              }
            }
            .user-list {
              padding-right: 8px;
            }
          }
        }
        .buttomRight {
          position: absolute;
          right: -4px;
          bottom: -6px;
          width: 30px;
          height: 30px;
        }
        .buttomLeft {
          position: absolute;
          left: -4px;
          bottom: -6px;
          width: 28px;
          height: 20px;
        }
      }
      .PreviewPhone_PURPLE {
        background-image: url("https://file.5rs.me/oss/uploadfe/png/e83242c9c520e085d11654ec1e056dd5.png");
        background-size: 100% 100%;
        padding: 15px 15px 20px 10px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .bg {
          position: relative;
          width: 100%;
          .contentBg {
            width: 100%;
            background: #5f3fde;
            margin: 9px -7px 0 7px;
            border: 2px solid #00052e;
          }
          .content {
            padding: 20px 10px 15px 10px;
            width: 100%;
            background-color: #ceebf9;
            border: 2px solid #00052e;
            position: relative;
            margin: -9px -7px 9px -7px;
            .btnGroup {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-bottom: 10px;
              .btnItem {
                width: 30%;
                height: 36px;
                margin-right: 5%;
                border-radius: 6px;
                line-height: 36px;
                font-size: 13px;
                margin-bottom: 16px;
                text-align: center;
                font-weight: 600;
                cursor: pointer;
                background: linear-gradient(180deg, #92eead 0%, #3cfabe 100%);
                color: #137345;
              }
              .activeBtn {
                background: linear-gradient(180deg, #7b73fc 0%, #863ff8 100%);
                color: #fff;
              }
            }
            .QRCode {
              width: 100%;
              text-align: center;
              .remark {
                height: 36px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 46px;
                margin-bottom: 25px;
                height: 46px;
                background: #ffffff;
                border-radius: 6px;
              }
              .img {
                padding: 10px;
                width: 210px;
                height: 210px;
                background: #ffffff;
                border-radius: 16px;
                margin: 0 auto 10px auto;
                img {
                  width: 190px;
                  height: 190px;
                }
              }
              .hint {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 17px;
              }
              .remark-bottom {
                color: #8543f8;
              }
              .specialArea {
                margin: 20px 15px 15px 15px;
              }
            }
          }
          .footerBg {
            position: relative;
            background: #5f3fde;
            margin: 30px -7px 0 7px;
            border: 2px solid #00052e;
          }
          .footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ceebf9;
            border: 2px solid #00052e;
            position: relative;
            margin: -9px -7px 9px -7px;
            padding: 30px 8px 10px 8px;
            .count {
              height: 34px;
              line-height: 34px;
              text-align: center;
              padding: 0 15px 0 25px;
              display: inline-block;
              position: absolute;
              right: -15px;
              top: -17px;
              word-break: keep-all;
              font-size: 16px;
              font-weight: 500;
              color: #030303;
              background-image: url("https://file.5rs.me/oss/uploadfe/png/e8bd2f49b4db685cc9128761c70203d1.png");
              background-size: 100% 100%;
              width: 276px;
              height: 46px;
            }
            .userHead {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 17px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 30px;
                height: 30px;
              }
            }
            .user-list {
              padding-right: 8px;
            }
          }
        }
        .bottomLeft {
          position: absolute;
          left: -32px;
          bottom: 22px;
          width: 79px;
          height: 59px;
        }
      }
      .PreviewPhone_BLUE {
        background: #0b53ff;
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .bg {
          position: relative;
          width: 100%;
          .contentBg {
            width: 100%;
            background-image: url("https://file.5rs.me/oss/uploadfe/png/78687830c9fecb5edc70469c0fc830e8.png");
            background-size: 100% 100%;
            padding: 10px 10px 6px 10px;
            position: relative;
          }
          .opacityBg {
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 136px;
            right: 10px;
            background: #84c1fc;
            border-radius: 18px;
            opacity: 0.37;
            z-index: 1;
          }
          .bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 136px;
          }
          .content {
            padding: 20px 10px 15px 10px;
            width: 100%;
            position: relative;
            z-index: 2;
            min-height: 140px;
            .btnGroup {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              margin-bottom: 10px;
              .btnItem {
                width: 30%;
                height: 36px;
                margin-right: 5%;
                line-height: 36px;
                font-size: 13px;
                margin-bottom: 16px;
                text-align: center;
                font-weight: 600;
                cursor: pointer;
                background: #fff;
                border-radius: 18px;
                color: #333333;
              }
              .activeBtn {
                background: #ed3c2d;
                > div {
                  border-radius: 36px;
                  background: #ff870d;
                  color: #ffffff;
                  height: 100%;
                  line-height: 39px;
                  margin: -3px 0 3px 0;
                }
              }
            }
            .QRCode {
              width: 100%;
              text-align: center;
              .remark {
                height: 36px;
                font-size: 14px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 46px;
                margin-bottom: 25px;
                height: 46px;
                background: #ffffff;
                border-radius: 6px;
              }
              .img {
                padding: 10px 10px 0 10px;
                width: 210px;
                height: 200px;
                background: #ffffff;
                border-radius: 16px 16px 0 0;
                margin: 0 auto;
                img {
                  width: 190px;
                  height: 190px;
                }
              }
              .hint {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: rgba(51, 51, 51, 0.5);
                line-height: 17px;
                background-color: #fff;
                width: 210px;
                height: 34px;
                line-height: 34px;
                margin: -5px auto 0px auto;
              }
            }
          }
          .footerBg {
            position: relative;
            padding: 35px 25px 30px 25px;
            background: linear-gradient(180deg, #1a8bff 0%, #0b53ff 100%);
          }
          .footer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            position: relative;
            padding: 40px 15px 5px 15px;
            border-radius: 0 0 9px 9px;
            .count {
              line-height: 41px;
              text-align: center;
              padding: 0 15px 0 25px;
              display: inline-block;
              position: absolute;
              right: -15px;
              left: -5px;
              right: -5px;
              top: -19px;
              word-break: keep-all;
              font-size: 12px;
              font-weight: 500;
              background-image: url("https://file.5rs.me/oss/uploadfe/png/7208756582c86d53d1c848c050c3dd59.png");
              background-size: 100% 100%;
              height: 41px;
              color: #ffffff;
            }
            .userHead {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              justify-content: space-between;
              margin-bottom: 17px;
              img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }
              span {
                display: inline-block;
                width: 30px;
                height: 30px;
              }
            }
            .user-list {
              padding-right: 15px;
            }
          }
        }
        .bottomLeft {
          position: absolute;
          left: -32px;
          bottom: 22px;
          width: 79px;
          height: 59px;
        }

        &_withUserList {
          .bg {
            .footerBg {
              padding: 18px 25px 30px 25px;
              .remark-bottom {
                color: #ffb102;
                margin-top: 0px;
                text-align: center;
              }
              .specialArea {
                margin: 20px 20px 55px 20px;
              }
            }
          }
        }
      }
    }
  }
}

@import "~@/assets/sass/_function";

.SelectWechatGroupWrapper-O4hR8h44 {
  .select-list {
    .select-item {
      display: flex;
      .title {
        position: relative;
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 26px;
        color: #3a3346;

        &::after {
          position: absolute;
          top: 0;
          transform: translateY(30%);
          content: "*";
          font-size: 15px;
          line-height: 1;
          color: #ff3668;
        }
      }
      .group-code-info-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 60px;

        .group-content {
          display: flex;
          width: 400px;
          padding: 10px;
          background-color: rgba($wx-primary-color, 0.05);
          margin-bottom: 10px;
          .wtdc-form-item {
            margin-bottom: 0px;
          }
          .previewImg {
            width: 110px;
            height: 110px;
            margin-bottom: 0px;

            .wtdc-form-item-label {
              display: none;
            }
          }

          .right-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 12px;
            .tips {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
              color: $gray9;
            }

            .operate-group-wrapper {
              display: flex;
              justify-content: flex-end;
              .operate-button {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: $wx-primary-color;
                padding-right: 15px;
                padding-left: 15px;
                cursor: pointer;
                &:not(:last-child) {
                  border-right: $wx-primary-color 1px solid;
                }
              }
            }
          }
        }
      }
      .delete-wrapper {
        cursor: pointer;
        .delete-icon {
          font-size: 18px;
          color: $gray9;
          &:hover{
            color: $private-domain-color
          }
        }
      }
    }
  }

  .add-group-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 36px;
    background: #ffffff;
    border: 1px solid $wx-primary-color;
    opacity: 1;
    border-radius: 4px;
    margin-left: 115px;
    cursor: pointer;
    .add-symbol {
      font-size: 11px;
      color: $wx-primary-color;
    }
    .add-group-btn {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 26px;
      color: $wx-primary-color;
      margin-left: 10px;
    }
  }
}

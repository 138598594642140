@import "~@/assets/sass/_function";

.BookEdition-AcjjvpTT {
  display: flex;
  align-items: center;
  .editionItem {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .di {
      margin-right: 10px;
    }
    .wtdc-form-item {
      margin-bottom: 0;
      margin-right: 10px;
      .wtdc-select {
        width: 80px !important;
      }
    }
  }
}

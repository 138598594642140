@charset "UTF-8";
@import "@/assets/sass/function";

.UEditor {
  .edui-toolbar {
    overflow: visible !important;
  }
  &.disabled .edui-editor-toolbarbox {
    display: none;
  }
  // 格式菜单按钮
  .edui-button.edui-for-formatMenuBtn .edui-button-wrap .edui-button-body .edui-icon {
    background-image: url("https://file.5rs.me/oss/uploadfe/png/78222b8397873ff0da239baf1ff31f41.png") !important;
    background-size: contain;
  }
  // 操作菜单按钮
  .edui-button.edui-for-operationMenuBtn .edui-button-wrap .edui-button-body .edui-icon {
    background-image: url("https://file.5rs.me/oss/uploadfe/png/a06ac6d9c527d02210ff30d1836f0352.png") !important;
    background-size: contain;
  }
  // 秀米
  .edui-button.edui-for-xiumi-connect .edui-button-wrap .edui-button-body .edui-icon {
    background-image: url("https://file.5rs.me/oss/uploadfe/png/97b0a5cf0c583c91839d852832eb69a9.png") !important;
    background-size: contain;
  }
  // 兼容ie下height设置失效的bug
  .edui-editor-iframeholder {
    min-height: 400px;
    .view {
      overflow-y: initial;
    }
  }
  .edui-for-xiumi-connect {
    position: relative;
    overflow: visible !important;

    // 兼容ie下height设置失效的bug
    .edui-editor-iframeholder {
      min-height: 400px;
      height: 400px;
      .view {
        overflow-y: initial;
      }
    }

    .xiumi-tip-wrapper {
      position: absolute;
      z-index: 2500;
      top: calc(100% + 10px);
      right: -20px;
      display: flex;
      padding: 5px 5px 5px 10px;
      width: 206px;
      color: $white;
      background-color: $colorPrimary;
      box-shadow: 2px 3px 0 $grayC;
      .tip-title {
        white-space: nowrap;
      }
      .tip-content {
        flex: 1;
      }
      .tip-close-btn {
        position: relative;
        top: 3px;
        margin-left: 10px;
        font-size: 10px;
        cursor: pointer;
      }
      &::after {
        position: absolute;
        right: 22px;
        top: -8px;
        display: block;
        content: "";
        @include triangle(top, 8px, $colorPrimary);
      }
    }
  }

  &.auto-width {
    width: 100%;
    .edui-editor {
      width: 100% !important;
      .edui-editor-iframeholder {
        width: 100% !important;
      }
    }
  }

  .edui-editor {
    display: flex;
    height: 100%;
    flex-direction: column;
    .edui-editor-iframeholder {
      min-height: 400px; //兼容ie给的默认高度
      flex: 1;
    }
    .edui-toolbar {
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(4) {
        display: none;
      }
      .edui-for-AudioTemplatesTemplatesBtn,
      .edui-for-VideoTemplatesTemplatesBtn {
        display: none !important;
      }
    }
    &.dynamic-menu-format {
      .edui-toolbar {
        &:nth-of-type(2) {
          display: block;
        }
      }
    }
    &.dynamic-menu-operation {
      .edui-toolbar {
        &:nth-of-type(3) {
          display: block;
        }
      }
    }
    &.dynamic-menu-table {
      .edui-toolbar {
        &:nth-of-type(4) {
          display: block;
        }
      }
    }
  }
}

// 预览
.edui-dialog.edui-for-preview {
  position: absolute;
  left: 0;
  right: 0;
  width: 360px;
  height: calc(100vh - 40px);
  margin: auto;
  .edui-dialog-body {
    height: 100%;
    .edui-dialog-content {
      width: 100% !important;
      height: calc(100% - 26px) !important;
    }
  }
}

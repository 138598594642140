@import "~@/assets/sass/_function";

.QrcodeFormItem_Ync6-cOO {
  .item-wrap {
    .list-item {
      padding-right: 30px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .move-button-wrap {
        width: 20px;
        height: 43px;
        background: #ffffff;
        border: 1px solid #dddddd;
        opacity: 1;
        border-radius: 4px;
        text-align: center;
        position: absolute;
        right: 0;
        @include center-translate(y);
        .move-btn {
          cursor: pointer;
          color: rgba($color: #3f51b5, $alpha: 0.2);
          transition: color 0.3s;
          &:hover {
            color: #3f51b5;
          }
          &.move-down {
            transform: rotate(180deg);
          }
          &.first-one,
          &.last-one {
            cursor: not-allowed;
            &:hover {
              color: rgba($color: #3f51b5, $alpha: 0.2);
            }
          }
        }
      }
      .qrcode-item {
        padding: 17px 0 0px 20px;
        width: 490px;
        min-height: 255px;
        background: #f6f5f9;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        .delete-icon {
          width: 37px;
          height: 37px;
          background: rgba($color: #4e50b8, $alpha: 0.5);
          border-radius: 0px 6px 0px 6px;
          font-size: 14px;
          color: #ffffff;
          line-height: 37px;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
          transition: background 0.3s;
          &:hover {
            background: rgba($color: #4e50b8, $alpha: 0.6);
          }
        }

        .wtdc-form-item {
          margin-bottom: 20px;
        }
        .qrcode-img {
          width: 80px;
          height: 80px;
        }

        .qrcode-desc {
          .wtdc-form-item-label-text::after {
            display: none;
          }
        }
      }
    }

    .qrcode-item {
      .ImageUpload .add-tips {
        display: flex;
        flex-direction: column;
      }
    }

    .switch-form-wrapper {
      height: 32px;
      margin-bottom: 20px;
      align-items: center;

      .wtdc-form-item {
        margin-bottom: 0 !important;

        .wtdc-form-item-children-wrapper {
          line-height: 30px;
        }
      }

      .tip {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .imageUpload-form-wrapper {
      padding-right: 16px;
      .ImageUpload {
        width: 180px;
        height: 80px;

        .wrapper {
          .ic-edit {
            width: 100%;
          }
        }
      }
      .desc {
        flex: 1;
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.qrcode-modal_Tq8L8r99 {
  .SearchTable {
    .wtdc-antd-table {
      .ant-table-body {
        height: 420px;
      }
    }
    .wtdc-pagination-small {
      text-align: center;
      margin-bottom: 0;
    }
    .code-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .code-img {
        width: 60px;
        height: 60px;
      }

      .desc-box {
        margin: 0 0 0 10px;

        .desc {
          //width: 270px;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          margin: 10px 0 0 0;
          font-size: 14px;
          line-height: 14px;
          color: #999999;
        }
      }
    }

    .live-code-categroy,
    .wechat-member {
      font-size: 14px;
      color: #3a3446;
    }
  }
}

@import "~@/assets/sass/_function";

.MessageList-n0NU6wx {
  padding: 0 20px;
  flex-wrap: wrap;
  .message-item {
    width: 553px;
    padding: 0 20px 20px;
    margin: 15px 10px;
    background: rgba(63, 81, 181, 0.06);
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    &-head {
      padding: 12px 0;
      justify-content: space-between;
      border-bottom: 1px solid #e9e9e9;
      .head-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        color: $white;
        font-size: 20px;
        background: #4e50b8;
        border-radius: 50%;
      }
    }
    &-content {
      position: relative;
      padding: 10px 0;
      .reply-tag {
        position: absolute;
        right: 0;
        width: 75px;
        height: 75px;
        background: url("https://oss.5rs.me/oss/uploadfe/png/f0043115a87bc161a942507edc162f31.png") no-repeat center /
          100%;
        &-await {
          background: url("https://oss.5rs.me/oss/uploadfe/png/b8ca1ec0398c92bff418cb4706ad08ae.png") no-repeat center /
            100%;
        }
      }
      &-title {
        display: inline-flex;
        font-size: 14px;
        line-height: 1;
        color: #3a3446;
      }
      &-detail {
        min-height: 100px;
        padding: 15px 20px;
        background: $white;
        border-radius: 4px;
        z-index: 10;
      }
    }
  }
}

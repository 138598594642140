@import "~@/assets/sass/_function";

.MsgList {
  border-radius: $radiusSmall;
  background-color: $white;

  .subitem-hot {
    display: block;
    position: relative;
    margin-left: 16px;
    margin-right: 15px;
    color: #464646;
    margin-bottom: 20px;
    //border-bottom: 1px solid $grayD;
    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      opacity: 0.5;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0, transparent 47%, transparent 0, transparent);
    }
    img {
      height: 120px;
      object-fit: cover;
    }
    h5 {
      font-size: 12px;
    }
    &.multi {
      h5 {
        width: 90%;
        position: absolute;
        left: 15px;
        bottom: 0;
        z-index: 8;
        color: $white;
        color: #ffffff;
        font-size: 14px;
        vertical-align: middle;
      }
      p {
        display: none;
      }
    }
  }
  .border-box {
    padding: 0 16px;
    box-sizing: border-box;
    margin-top: 10px;
    width: 100%;
    height: 80px;
    position: relative;
    border-bottom: 1px solid transparent;
    .space-border {
      position: absolute;
      right: 15px;
      top: 79px;
      width: 88%;
      height: 1px;
      background-color: #eeeeee;
    }
    .subitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $gray3;
      // &.line {
      //   border-bottom: 1px solid $grayD;
      // }
      .text,
      .WTDCImg-EkIN2Gdd {
        // display: table-cell;
        vertical-align: middle;
      }
      .text {
        padding-right: 12px;
        p {
          max-width: 180px;
          max-height: 48px;
          line-height: 24px;
          @extend %ellipsis-lines;
        }
      }
      .WTDCImg-EkIN2Gdd {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        border: 1px solid #ddd;
      }
    }
  }
  .empty {
    padding: 30px 22px;
    text-align: center;
    .ic {
      font-size: 140px;
      color: $gray9;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
      color: $colorPrimary;
    }
  }
  .EmptyResult {
    top: 18%;
    margin-left: -75px;
    height: auto;
    .info-wrap {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .MsgList {
    .subitem-hot {
      img {
        height: 114px;
      }
    }
    > div {
      // height: 300px !important;
    }
  }
}

@import "~@/assets/sass/_function";

.userList-search {
  //display: flex;
  flex-wrap: wrap;
  padding: 14px 4px 0;
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: $white;
  border: 1px solid #e9e9e9;
  //overflow: hidden;

  .search-icon {
    white-space: nowrap;
  }

  .searchSet {
    padding: 16px 20px;
    cursor: pointer;
  }

  &-form {
    padding: 0 20px;
  }

  &-item {
    flex: 1;
  }

  &-list {
    align-items: baseline;

    .search-label {
      flex-shrink: 0;
      width: 84px;
      text-align: right;
    }
  }

  .wtdc-input-number-handler-wrap {
    opacity: 0;
  }

  &-input {
    display: inline-block;
    width: 25%;
    padding: 0 20px;
    .active-item {
      .wtdc-select-selection-placeholder {
        color: $gray3;
      }
    }
    .wtdc-form-item {
      display: flex;
      margin-bottom: 24px;

      .wtdc-select-multiple {
        .wtdc-select-selection-search-input {
          min-width: 0;
          margin-left: 0;
        }
      }
      .wtdc-form-item-label {
        display: inline-flex;
        align-items: center;
        flex-shrink: 0;
        width: 84px;
        margin-right: 10px;
        padding: 0;
        font-size: 14px;
        text-align: right;
        white-space: normal;
      }
      &-children-wrapper,
      .wtdc-input-number,
      .wtdc-calendar-picker,
      .wtdc-form-item-label-text,
      .wtdc-input,
      .wtdc-select-show-search {
        width: 100% !important;
      }
    }
  }

  .range-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    &-input {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .wtdc-form-item {
      margin: 0;
    }
    &-label {
      display: inline-flex;
      width: 84px;
      justify-content: flex-end;
      white-space: nowrap;
      flex-shrink: 0;
    }
    .wtdc-form-item-label {
      display: none;
    }
    &-separator {
      white-space: nowrap;
    }
  }

  .cascade-form {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .ant-cascader-picker {
      width: 100%;
    }
    &-label {
      display: inline-flex;
      width: 84px;
      justify-content: flex-end;
      white-space: nowrap;
      flex-shrink: 0;
    }
  }

  &-btn {
    width: 25%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 20px;
    .export-btn {
      .wtdc-icon-daoru1 {
        transform: rotate(180deg);
      }
    }
  }
}

// 配置搜索
.SearchSet-MMnxJwx {
  .modal-content {
    padding: 40px;
  }
  .search-content {
    &-list {
      margin-bottom: 20px;
    }
    &-label {
      min-width: 85px;
      margin-right: 20px;
      text-align: right;
    }
    &-item {
      display: inline-flex;
      align-items: center;
      margin: 0 20px 20px 20px;
      white-space: nowrap;
    }
  }
}

@import "~@/assets/sass/_function";

.PushAccount {
  .add-push {
    &-head {
      align-items: center;
      padding: 25px 40px;
      border-radius: 12px;
      background-color: $white;
    }
  }
  .change-account {
    color: $colorPrimary;
    border-radius: 4px;
    background: #f6f5f9;
  }
}

.PushAccountModal {
  .modal-content {
    display: flex;
    flex-direction: column;
    .account-list {
      display: flex;
      flex-wrap: wrap;
      min-height: 320px;
    }
    .account-item {
      margin: 8px;
      padding: 20px;
      width: 31%;
      height: 100px;
      align-items: center;
      background: #f6f5f9;
      opacity: 1;
      border-radius: 6px;
      &-box {
      }
    }
  }
  .modal-footer {
    padding: 10px 22px;
    text-align: right;
  }
}

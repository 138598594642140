@import "~@/assets/sass/_function";

.BgFromItem_Ync6-cOO {
  .bg-image-wrap {
    display: inline-block;
    margin-right: 20px;
    // width: 90px;
    height: 180px;
    border-radius: 10px;
    background: #f6f5f9;
    border: 2px solid transparent;
    overflow: hidden;
    position: relative;
    &.actived {
      border: 2px solid #4e50b8;
      .wtdc-icon {
        font-size: 24px;
        color: #4e50b8;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        @include center-translate;
      }
    }
    &:hover {
      border: 2px solid #4e50b8;
      cursor: pointer;
    }
    .bg-image {
      width: 90px;
      height: 180px;
    }
  }
}

@import "~@/assets/sass/_function";

.MemberModalWrap {
  .wtdc-form-item-label {
    min-width: 100px !important;
    width: 110px;
    padding-right: 10px;
  }
  // .wtdc-form-item-label-text::after {
  //   display: none;
  // }

  .add-member-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 660px;
    .member-item {
      margin-right: 10px;
      margin-bottom: 14px;
      height: 32px;
      background: #ebe9f2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      padding: 0 6px;
      .member-img-wrap {
        width: 22px;
        height: 22px;
        background: #ffffff;
        border-radius: 50%;
        position: relative;
      }
      .member-img {
        width: 12px;
        height: 12px;
        @include center-translate;
      }
      .member-name {
        padding: 0 8px 0 4px;
      }
      .wtdc-icon-delete {
        color: #ccc;
        transition: color 0.3s;
        cursor: pointer;
        &:hover {
          color: darken($color: #ccc, $amount: 10);
        }
      }
    }
  }
}

.MemberModal_Pf3_TK33 {
  .modal-content {
    height: 70vh;
    min-height: 540px;
    max-height: 800px;
    .left-content {
      // height: 100%;
      padding-bottom: 50px;
    }
    .member-info {
      // margin-left: -20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .wtdc-icon {
        color: #cccccc;
      }
      .member-img,
      .wtdc-img-transparent-mask {
        width: 48px;
        height: 48px;
        opacity: 1;
        border-radius: 50%;
        overflow: hidden;
        padding: 13px;
      }

      .wtdc-img-transparent-mask {
        border: 1px solid #f0f0f0;
      }

      .desc-box {
        font-size: 15px;
        font-weight: bold;
        line-height: 15px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0 0 0 14px;
      }
    }
  }
}

@import "~@/assets/sass/_function";

.EmptyResult {
  width: 100%;
  overflow: hidden;
  margin: 120px 0;
  background-color: $colorBg;
  text-align: center;
  &__container {
    display: inline-block;
    &--logo,
    &--text {
      display: table-cell;
      vertical-align: middle;
      padding-left: 5px;
      padding-right: 5px;
    }
    &--logo {
      line-height: 1;
      font-size: 140px;
      text-align: center;
      color: $gray9;
    }
    .info-img {
      width: 150px;
      margin: 0 auto;
      display: block;
      background-position: center;
      background-size: contain;
      height: 150px;
    }
    &--text {
      font-size: 14px;
      display: block;
    }
    &--wrap {
      .info-img {
        display: inline-block;
        width: 180px;
        height: 150px;
        background-position: center;
        background-repeat: no-repeat;
      }
      .big-text {
        line-height: 24px;
        font-size: 14px;
        .ShowAddModel {
          line-height: 24px;
          vertical-align: bottom;
          .text-warning {
            font-size: 14px;
            line-height: 24px;
            border: none;
          }
        }
      }
    }
  }
}

@import "~@/assets/sass/_function";

.WorkPeriod_88vcWS77 {
  .wtdc-select-selection-search {
    overflow: hidden;
  }
  .wtdc-form-item-label-text::after {
    content: "*";
    font-size: 15px;
    line-height: 1;
    color: #ff3668;
    position: absolute;
    @include center-translate(y);
  }
  .wtdc-form-item {
    margin-bottom: 20px;
  }

  .work-wrap {
    padding-left: 124px;
    .ant-picker:hover {
      border-color: rgba($color: $primary-color, $alpha: 0.5);
    }
  }
}

@import "~@/assets/sass/_function";
.Sop-RBKmPe11 {
  .sop-table {
    // padding: 0 20px 20px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .table-wrap {
      flex: 1;
      width: 100%;
      overflow: auto;
    }
    .code-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .code-img {
        width: 60px;
        height: 60px;
      }

      .desc-box {
        margin: 0 0 0 10px;

        .desc {
          //width: 270px;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          margin: 10px 0 0 0;
          font-size: 14px;
          line-height: 14px;
          color: #999999;
        }
      }
    }

    .sop-categroy,
    .wechat-member,
    .scanning-and-adding-times {
      font-size: 14px;
      color: #3a3446;
    }

    // .sop-categroy, .wechat-member, .th-sop-categroy, .th-wechat-member{
    //   width: 126px;
    // }
  }
}

@import "~@/assets/sass/_function";

.singleEditLabel_b_9-b9ZZ {
  .modal-footer {
    padding: 10px 20px;
    text-align: right;
    .confirm-btn {
      margin-left: 23px;
    }
  }
}

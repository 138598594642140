@import "~@/assets/sass/_function";

.BrowseList-4hhQdsH {
  padding: 6px 20px;
  .browse-item {
    &-goodsName {
      @include ellipsis-lines(2);
      &-cover {
        border-radius: 4px;
      }
    }
  }
}

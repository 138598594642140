@import "~@/assets/sass/_function";

.addPushHome {
  height: 100%;
  display: flex;
  flex-direction: column;
  .alignBase {
    align-self: baseline;
  }

  .add-push {
    &-search {
      position: relative;
      .PushUserSearch {
        padding: 24px 14px 0;
        .pushTypeItem {
          padding: 0 20px;
          .wtdc-form-item-label {
            font-weight: 600;
          }
        }
        .userList-search-btn {
          width: auto;
          justify-content: center;
        }
      }
    }
    &-content {
      flex: 1;
      padding: 24px;
      border-radius: 12px;
      background-color: $white;
      &-title {
        padding-bottom: 24px;
        margin-bottom: 18px;
        line-height: 1;
        border-bottom: 1px solid #e9e9e9;
      }
      &-time {
        margin-bottom: 4px;
        align-items: center;
        .wtdc-form-item {
          line-height: 32px;
        }
      }
      &-time_input {
        margin-left: 10px;
        .wtdc-form-item-label {
          display: none;
        }
      }
      &-type {
        &-label {
          margin-right: 48px;
          flex-shrink: 0;
        }
        &-active {
          padding: 24px;
          border: 1px dashed #bebebe;
          border-radius: 10px;
          text-align: center;
        }
        &-btn {
          margin: 0 12px;
        }
      }
    }
    &-template {
      &-back {
        position: absolute;
        right: 0;
        top: 0;
        width: 37px;
        height: 37px;
        background: #4e50b8;
        opacity: 0.5;
        color: $white;
        cursor: pointer;
        border-radius: 0px 6px 0px 6px;
      }
      &-content {
        position: relative;
        min-width: 60%;
        padding: 24px;
        background: #f6f5f9;
        border-radius: 10px;
      }
      &-item {
        align-items: center;
      }
      &-btn {
        padding: 10px 18px;
        margin-left: 20px;
        line-height: 1;
        background-color: $white;
        cursor: pointer;
        border: 1px dashed $colorPrimary;
      }
      &-index {
        display: inline-flex;
        width: 20px;
        height: 20px;
        margin-right: 13px;
        align-items: center;
        justify-content: center;
        color: $white;
        background: #c8c5d0;
        border-radius: 50%;
      }
    }
    &-customer {
      &-content {
        position: relative;
        flex: 1;
        max-width: 935px;
        padding: 24px;
        background: #f6f5f9;
        border-radius: 10px;

        .customer-message {
          flex: 1;
          max-width: 65%;
          padding: 20px 35px;
          border-radius: 10px;
          background-color: $white;
          &-title {
            font-size: 14px;
            font-weight: 600;
          }
          &-text,
          &-picture,
          &-link {
            display: flex;
            flex-direction: column;
            .wtdc-form-item {
              display: flex;
              //align-items: baseline;
            }
            .wtdc-form-item-label {
              width: 86px;
              text-align: right;
            }
            .wtdc-form-item-children-wrapper {
              flex: 1;
            }
            .image-picker-tip {
              max-width: 210px;
              margin-left: 10px;
              font-size: 14px;
              color: $gray9;
            }
          }
        }
      }
      &-type {
        align-items: center;
      }
      &-type-active {
        //flex: 1;
        min-width: 520px;
        padding: 24px;
        border: 1px dashed #bebebe;
        border-radius: 10px;
        background-color: $white;
        text-align: center;
      }
    }
  }
  .template-message {
    &-content {
      flex: 1;
      padding: 20px 24px;
      border-radius: 10px;
      background-color: $white;
    }
    &-info {
      align-items: baseline;
    }
    &-example {
      flex: 1;
      max-width: 286px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      padding: 32px 0 0;
      align-self: normal;
      margin-right: 15px;
      border: 1px solid $grayD;
      border-radius: 6px;
      &-tag {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 10px;
        background: #ebebeb;
        line-height: 1;
        border-radius: 6px 0px 6px 0px;
      }
      &-text {
        padding: 0 15px;
      }
      &-detail {
        padding: 10px 16px;
        line-height: 1;
        border-top: 1px solid $grayE;
      }
    }
    &-setting {
      flex: 1;
      flex-direction: column;
      .template-item {
        flex: 1;
        display: flex;
        align-items: baseline;
        margin-bottom: 24px;
        .wtdc-form-item-label {
          width: 68px;
          font-size: 14px;
          padding-right: 10px;
          text-align: right;
        }
        .wtdc-form-item-children-wrapper {
          flex: 1;
        }
        .wtdc-input {
          width: 100% !important;
        }
      }
      &-desc {
        display: flex;
        flex-direction: column;
        padding-left: 80px;
      }
      &-label {
        margin-bottom: 16px;
      }
    }
  }
}

.checkPushMessage {
  pointer-events: none;
  .PushUserSearch,
  .change-account,
  .add-push-template-back,
  .wtdc-icon-yichu {
    display: none;
  }
}

.add-push-bottom {
  padding: 12px;
  margin: 0 -20px -20px;
  background-color: $white;
  > button {
    min-width: 110px;
  }
}

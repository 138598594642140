@import "~@/assets/sass/_function";
.LookFilterUserModal-5lE7yldd {
  .search-table-wrapper {
    width: 100%;
    min-height: 600px;
    .table-title {
      color: $gray3;
      font-size: 16px;
      margin-bottom: 30px;
    }
    .SearchTable {
      display: flex;
      flex-direction: column;
      width: 100%;
      .table-wrap,
      .ant-table-row {
        height: 0;
        flex: 1;
        overflow: auto !important;
        .user-info {
          display: flex;
          align-items: center;
          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            img {
              border-radius: 50%;
            }
          }
          .name {
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 15px;
            color: $gray3;
            margin-left: 10px;
          }
        }
      }

      .WTDCImg-EkIN2Gdd {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@charset "UTF-8";
@import "variables";

/** text color **/
.text-default {
  color: $text-color;
}

.text-primary {
  color: $primary-color;
}

.text-primary-hover {
  color: $primary-color;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: lighten($primary-color, 10%);
  }
}

.text-secondary {
  color: $secondary-color;
}

.text-contrast {
  color: $contrast-color;
}

.text-info {
  color: $info-color;
}

.text-warning {
  color: $warning-color;
}

.text-gray3 {
  color: #333;
}

.text-gray6 {
  color: #666;
}

.text-gray9 {
  color: #999;
}

.text-grayC {
  color: #ccc;
}

.text-white {
  color: $white;
}

.text-black {
  color: $black;
}

/** font size **/
.f12 {
  font-size: 12px;
  line-height: 20px;
}

.f14 {
  font-size: 14px;
  line-height: 22px;
}

.f16 {
  font-size: 16px;
  line-height: 24px;
}

.f18 {
  font-size: 18px;
  line-height: 26px;
}

.f20 {
  font-size: 20px;
  line-height: 28px;
}

.f24 {
  font-size: 24px;
  line-height: 32px;
}

.f30 {
  font-size: 30px;
  line-height: 38px;
}

.f46 {
  font-size: 46px;
  line-height: 54px;
}

.f68 {
  font-size: 68px;
  line-height: 76px;
}

/** width **/
.w120 {
  width: 120px;
}

.w160 {
  width: 160px;
}

// margin
.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt60 {
  margin-top: 60px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}

.ml120 {
  margin-left: 120px !important;
}

/** 通用页面title样式 **/
.page-title {
  position: relative;
  display: inline-block;
  font-size: 30px;
  letter-spacing: 0.1em;
  color: $text-color-6;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 8px;
    height: 8px;
    width: 100%;
    background-color: rgba($primary-color, 0.1);
  }
}

.primary-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

// button
.btn {
  height: $btn-height;
  line-height: $btn-height - 2;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  min-width: 110px;
  padding: 0 15px;

  &.disabled {
    background-color: #ddd;
    color: white;
  }

  > i {
    height: $btn-height;
    line-height: 34px;
  }
}

.btn-primary {
  color: white;
  border-color: $primary-color;
  background-color: $primary-color;

  &:hover {
    background-color: lighten($primary-color, 5%);
    color: $white;
  }
  &.wtdc-form-button.wtdc-form-button-disabled,
  &.wtdc-form-button.wtdc-form-button-disabled:hover {
    color: white;
    background-color: rgba($primary-color, 0.5);
    cursor: not-allowed;
  }
}

.btn-default {
  color: $primary-color;
  background-color: white;
  border-color: $primary-color;

  &:hover {
    color: white;
    background-color: $primary-color;
  }
}

.export-btn {
  background-color: white;
  border-color: #ddd;
  color: #3a3446;
  &:hover {
    border-color: $primary-color;
    color: $primary-color;
    background-color: white;
  }
}
.btn-gray {
  color: $text-color;
  background-color: #f8f8f8;
  border-color: #ddd;

  &:hover {
    background-color: white;
  }
}

.btn-transparent {
  color: white;
  background-color: transparent;
  border-color: #ddd;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
}

// transition
.transition-all {
  transition: all 0.3s;
}

.transition-color {
  transition: color 0.3s;
}

.transition-bg-color {
  transition: background-color 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}

// display
.inline-block {
  display: inline-block !important;
  vertical-align: middle !important;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

//flex
.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-shrink {
  flex-shrink: 0;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.row-flex {
  flex-direction: row;
}

.col-flex {
  flex-direction: column;
}

.dis-flex {
  display: flex;
}

.center-flex {
  justify-content: center;
  align-items: center;
}

.flex-justify {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.flex1 {
  flex: 1;
}

.required {
  position: relative;

  &::after {
    content: "*";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $warning-color;
    font-size: 15px;
    line-height: 1;
  }
}

.hidden {
  display: none !important;
}
.UEditorContent {
  .view {
    padding: 0;
    word-wrap: break-word;
    cursor: text;
    height: 90%;
  }

  iframe {
    display: block;
    // margin: 10px 0;
  }

  body {
    margin: 8px;
    font-family: sans-serif;
    font-size: 16px;
  }
}

.mpa-hide {
  display: none !important;
}

.disabled {
  pointer-events: none;
  cursor: none;
}

.bold {
  font-weight: 600;
}

.underline {
  cursor: pointer;
  color: $colorPrimaryLight;
  text-decoration: underline;
}

.border-dashed {
  border-style: dashed;
}

// TOOLTIPS
.Tooltip {
  .wtdc-icon-wenhao {
    margin-left: 6px;
    color: #b3b0b9;
    vertical-align: text-bottom;
  }
  .wtdc-icon-wenhao:hover {
    color: #4e50b8;
  }
}

@import "~@/assets/sass/_function";

.SelectSendUserForm-sU54MShh {
  height: 100%;
  overflow:auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background-color: #d5d5d5;
    cursor: pointer;
  }
  .select-send-user-wrapper {
    padding-bottom: 50px;
    .back-button {
      display: flex;
      width: 105px;
      padding-left: 11px;
      padding-right: 11px;
      height: 32px;
      align-items: center;
      background-color: rgba($wx-primary-color, 0.1);
      border-radius: 8px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:hover {
        cursor: pointer;
      }

      .back-icon {
        font-size: 11px;
        color: red !important;
        transform: rotate(90deg);
      }

      .title-text {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: $wx-primary-color;
      }
    }

    .send-user-content {
      .info-title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 34px;
        color: #3a3446;
        margin-bottom: 30px;
      }

      .select-group-user-wrapper {
        display: flex;
        margin-bottom: 10px;

        .title {
          position: relative;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 26px;
          color: #3a3346;

          &::after {
            position: absolute;
            top: 0;
            transform: translateY(30%);
            content: "*";
            font-size: 15px;
            line-height: 1;
            color: #ff3668;
          }
        }

        .AddMemberWrapper-TaGzUbxx {
          margin-left: 70px;
        }
      }

      .select-user {
        .wtdc-form-item-children-wrapper {
          margin-left: 50px;
        }
      }

      .filter-user-content {
        width: 775px;
        margin-left: 130px;
        padding-top: 20px;
        padding-left: 20px;
        background-color: rgba($wx-primary-color, 0.04);
        border-radius: 4px;

        .book-label {
          display: inline-block;

          .booklabel--title,
          .booklabel--content {
            display: inline-block;
          }

          .booklabel--title {
            .SelectBookLabel-i9Lnkkii{
              margin-left: 30px;
            }
            .wtdc-form-item {
              margin-bottom: 0px;
            }
          }
          .wtdc-form-item {
            margin-bottom: 20px;
          }

          .booklabel__item--wrapper {
            // margin-bottom: 0px;

            .booklabel__item--title {
              width: 45px;
            }
          }
        }
      }

      .look-statistics-user {
        margin-top: 10px;
        margin-left: 130px;
        margin-bottom: 30px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color:$wx-primary-color;
        text-decoration: underline;
        cursor: pointer;
      }

      .guide-wrapper {
        position: relative;
        display: flex;
        margin-bottom: 30px;

        .guide-title {
          position: relative;
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: $gray3;
          margin-right: 23px;

          &::after {
            position: absolute;
            top: 0;
            transform: translateY(30%);
            content: "*";
            font-size: 15px;
            line-height: 1;
            color: #ff3668;
          }
        }

        .emoji-editor {
          width: 642px;
        }
      }

      .group-send-time {
        .wtdc-form-item-children-wrapper {
          margin-left: 50px;
        }
      }

      .select-time-wrapper {
        width: 770px;
        margin-left: 130px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        background-color: rgba($wx-primary-color, 0.04);
        border-radius: 4px;

        .select-time {
          width: 100%;
          margin-bottom: 0px;
        }

        .group-dateRange {
          width: 650px !important;
        }
      }
    }

    .foot-wrapper {
      margin-left: 130px;
      margin-top: 30px;

      .finish-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 32px;
        background-color: $wx-primary-color;
        border-radius: 2px;
        color: $white;
        cursor: pointer;

        &.disable {
          background-color: rgba($private-domain-color, 0.5);

          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

.wtdc-calendar-picker-container {
  z-index: 1499;
}

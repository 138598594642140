@import "~@/assets/sass/_function";

.ConditionLayers-UnwzEJz {
  .modal-content {
    padding: 38px 30px;
    .wtdc-form-item {
      display: flex;
      .wtdc-form-item-label {
        flex-shrink: 0;
        margin-right: 20px;
      }
      .wtdc-radio-group {
        display: flex;
        flex-wrap: wrap;
        .wtdc-radio {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@import "~@/assets/sass/_function";
.AddMemberWrapper-TaGzUbxx {
  .select-list {
    display: flex;
    flex-wrap: wrap;
    width: 700px;
    align-items: center;
    .select-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .member-img,
      .wtdc-img-transparent-mask {
        width: 48px;
        height: 48px;
        opacity: 1;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 10px 0 0;
      }
    }
    .modify-btn {
      line-height: 48px;
      color:$wx-primary-color;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

@import "~@/assets/sass/_common.scss";
@import "~@/assets/sass/_animation.scss";
@import "~@/assets/sass/_base";
@import "~@/assets/sass/_antd.scss";
@import "~@wtdc/tabs/sass/tabs.scss";

body {
  overflow: hidden;
}

#App {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  min-height: 600px;
  @include tablet {
    min-width: auto;
  }
}

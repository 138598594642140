@import "~@/assets/sass/_function";

.RuleModal-xqws212 {
  .form-item-skipSpeakKeyword {
    margin-bottom: 10px;
  }
  .form-item-speakKeyword {
    .wtdc-form-item-label {
      visibility: hidden;
    }
  }
  .modal-content {
    height: 600px !important;
    padding-left: 30px;
  }
}

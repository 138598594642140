@import "~@/assets/sass/_function";

.UserList {
  display: flex;
  flex-direction: column;

  // *[title="请选择"] {
  //   color: $grayC;
  // }
  .userLabel {
    position: relative;
  }

  .userList-content {
    flex: 1;
    border-radius: 12px;
    background-color: $white;
    border: 1px solid #e9e9e9;
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 14px 20px;
      .select-length {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        opacity: 1;
        margin-right: 22px;
      }
      .export-btn {
        align-self: flex-end;
        // color: $gray3;
        .wtdc-icon-daoru1 {
          transform: rotate(180deg);
          line-height: 38px;
        }
      }
    }
    .tab-list {
      padding: 0 20px 20px;
      .user-info {
        align-items: center;
        .user-name {
          @include ellipsis-lines(2);
        }
        .wtdc-img-inner {
          border-radius: 50%;
        }
      }
    }
    .ant-table-sticky-scroll {
      visibility: hidden;
    }
  }
}

.unbindLayer {
  .modal-footer {
    padding: 9px 20px;
    text-align: right;
  }
}

.wtdc-select-selector {
  display: flex;
}

.ant-tree-list {
  .ant-tree-treenode-disabled {
    .ant-tree-title {
      font-size: 15px;
      color: $gray3 !important;
      font-weight: 600;
    }
    .ant-tree-checkbox-inner {
      display: none;
    }
  }
  .ant-tree-node-selected {
    .ant-tree-title {
      color: $colorPrimary;
    }
  }
  .ant-tree-treenode {
    .ant-tree-indent-unit {
      &::before {
        border-right-style: dashed;
      }
    }
    .ant-tree-switcher {
      color: $colorPrimary;
      &-leaf-line {
        &::after {
          border-bottom-style: dashed;
        }
        &::before {
          border-left-style: dashed;
        }
      }
    }
  }
}

.wtdc-pagination {
  font-weight: normal !important;
}

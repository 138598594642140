@import "~@/assets/sass/_function";

.CreateNewKeyword {
  // padding: 0 20px 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #fff;

  .title {
    margin: 0 0 30px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 34px;
    color: #3a3446;
  }

  .wtdc-form-item-label {
    width: 80px;
  }

  .btn-warp {
    margin: 0 0 0 80px;
  }
}

.back-button {
  display: flex;
  width: 105px;
  padding-left: 11px;
  padding-right: 11px;
  height: 32px;
  align-items: center;
  background-color: rgba($wx-primary-color, 0.1);
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 30px;

  &:hover {
    cursor: pointer;
  }

  .back-icon {
    font-size: 11px;
    color: $wx-primary-color;
    transform: rotate(90deg);
  }

  .title-text {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 24px;
    color: $wx-primary-color;
  }
}

@import "~@/assets/sass/_function";
@import "~@wtdc/input-number/sass/input-number";

$inputNumberPrefixCls: "wtdc-input-number";

.#{$inputNumberPrefixCls} {
  &-handler-wrap {
    opacity: 1;
  }
}

@import "~@/assets/sass/_function";

.ScanBook-suZsks6 {
  padding: 6px 20px;
  .book-info {
    &-name {
      @include ellipsis-lines(2);
    }
  }
}

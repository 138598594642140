@import "~@/assets/sass/_function";

.PreviewImg {
  .modal-content {
    text-align: center;
    background-color: #000;
  }
  .img {
    width: 350px;
    height: auto;
    margin: 30px 60px;
  }
}

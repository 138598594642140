@import "~@/assets/sass/_function";
.AddTagWrapper-RBXSDDC {
  .select-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;

    &-item {
      height: 32px;
      line-height: 32px;
      padding: 0 9px 0 14px;
      margin: 5px 0 5px 10px;
      background: #f6f5f9;
      opacity: 1;
      border-radius: 4px;

      .wtdc-icon-cha1 {
        font-size: 13px;
        color: #ccc;
        padding: 0 5px;
        cursor: pointer;
      }
    }

    .modify-btn {
      line-height: 48px;
      color: $wx-primary-color;
      font-size: 15px;
      cursor: pointer;
    }
  }
  .AddButton {
    border-style: dashed;
  }
}

@import "~@/assets/sass/_function";

.wtdc-modal {
  .modal-table-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .modal-title {
    padding: 0 14px;
    height: 40px;
    line-height: 40px;
    background-color: #f2f2f5;
    border-radius: 4px 4px 0 0;
    color: #3a3446;
  }

  .modal-close-btn {
    position: absolute;
    right: 8px;
    top: 7px;
    padding: 5px;
    font-size: 13px;
    color: #999;
    z-index: 1;
    transition: color 0.3s;
    &:hover {
      color: #333;
    }
  }

  .modal-footer {
    height: 50px;
    padding-top: 9px;
    background-color: #f2f2f5;
    text-align: center;

    > button {
      min-width: 64px;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .modal-content {
    padding: 20px 20px;
  }

  .modal-table-container {
    display: table;
  }

  .modal-table-cell {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    position: relative;
    display: inline-block;
    background-color: $white;
    border-radius: 4px;
    text-align: left;
  }

  &.fadeIn {
    animation: fadeIn 0.2s 1 linear;
  }
}

.ant-modal-content {
  .ant-btn {
    float: right;
    margin-left: 8px;
  }
  .ant-btn-primary {
    float: none;
  }
}

@import "~@/assets/sass/_function.scss";

.modify-modal_SMhS22UU {
  position: relative;

  .wtdc-form-item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    &::after {
      // content: "个";
      @include center-translate(y);
      right: -10px;
      font-size: 14px;
      line-height: 20px;
    }
    &-label {
      padding: 0;
      line-height: 20px;
    }
    .wtdc-input-number {
      width: 38px;
      margin: 0 5px;
      border-top: none;
      border-right: none;
      border-left: none;
      height: 22px;
      &-input {
        padding: 0 2px;
        height: 20px;
        text-align: center;
      }
      &-handler-wrap {
        display: none;
      }
    }
  }
}

.modify-modal_save-btn{
  margin: 0 0 0 52px !important;
}
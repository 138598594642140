@import "~@/assets/sass/_function";

.MineHome-S1y2S2hh {
  background-color: $white;
  .ant-form-item-label {
    width: 90px;
    text-align: left;
  }

  .password-form,
  .account-form {
    margin-top: 30px;
    padding-left: 25px;

    &__button {
      width: 100px;
      margin-left: 105px;
    }
  }

  .account-form {
    &__head {
      width: 70px;
      height: 70px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      .image-picker-tip {
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        width: 70px;
        margin-bottom: 0;
        z-index: 10;
        position: absolute;
        bottom: 0;
        color: white;
        line-height: 20px;
        font-size: 12px;
      }
      .image-picker-block {
        border: none;
      }
      .re-upload-btn {
        z-index: 11;
        margin-left: 0 !important;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 70px;
        text-align: center;
        color: white;
        text-decoration: none;
        font-size: 0;
      }
      &--modify {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: red;
      }
    }

    &__button {
      width: 100px;
      margin-left: 91px;
    }

    .wtdc-form-item-label {
      width: 90px;
      text-align: left;
    }
  }

  .password-form {
    .wtdc-form-item-label {
      width: 105px;
      text-align: left;
    }
  }
}

@import "~@/assets/sass/_function";

.ClipRect {
  display: flex;
  padding: 0 10px;

  .clip-content,
  .clip-preview {
    text-align: center;

    &.clip-preview {
      flex: 1;
    }

    &_head {
      margin-bottom: 27px;
      @extend %f16;
      color: #424242;
    }

    &_area {
      height: 300px;

      &.left-area {
        //margin-bottom: 17px;
        padding-right: 40px;
        border-right: 1px solid $border-color;
      }

      .crop-canvas {
        display: none;
      }

      &.right-area {
        padding-left: 40px;
        border-left: 1px solid $border-color;
      }
    }

    &_image {
      &.clip-image {
        height: 100%;
        width: 300px;
        border: solid 1px $border-color;
        background-color: #f5f5f5;
        @include center-flex;
      }

      &.preview-image {
        height: 200px;
        width: 100%;
        vertical-align: middle;
        overflow: hidden;
      }
    }

    &_footer {
      margin-top: 17px;

      &.left-footer {
        padding-right: 40px;
      }

      &.right-foot {
        cursor: pointer;
      }
    }
  }
}

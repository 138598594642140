@import "~@/assets/sass/_function";

.AddMemberWrapper-xqws212 {
  width: 100%;

  .addBtn {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .AddButton {
      margin-left: 10px;
    }
  }

  .select-list {
    display: flex;
    flex-wrap: wrap;
    &-item {
      background: #ebe9f2;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px 5px 6px;
      margin-top: 16px;

      &-icon {
        background: #ffffff;
        border-radius: 50%;
      }

      &-name {
        flex: 1;
        margin: 0 7px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        @include ellipsis-lines(1);
      }

      .wtdc-icon-delete {
        width: 13px;
        height: 13px;
        color: #cccccc;
      }
    }
  }

  .seeMore {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 0px;
    color: #4e50b8;
    margin-top: 15px;
    user-select: none;
    cursor: pointer;

    &-unfold {
      .wtdc-icon-unfold {
        transform: rotate(180deg);
      }
    }

    .wtdc-icon-unfold {
      margin-left: 10px;
      transition: all ease 0.2s;
    }
  }

  &-1,
  &-2 {
    .select-list-item {
      width: 241px;
      height: 32px;
      padding: 5px 10px 5px 6px;
      margin-top: 16px;
      &:nth-child(2n) {
        margin-left: 14px;
      }

      &-icon {
        width: 22px;
        height: 22px;
        padding: 5px;
      }
    }
  }

  &-3 {
    .select-list-item {
      width: 155px;
      height: 42px;
      margin-right: 16px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &-icon {
        width: 32px;
        height: 32px;
        padding: 9px;
      }
    }
  }
}

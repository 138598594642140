@import "~antd/lib/cascader/style/index";
@import "~antd/lib/input/style/index";

$colorPrimary: #4e50b8;
$inputWidth: 230px;
$box-shadow-base: 0 0 0 2px rgba($colorPrimary, 0.2);

.ant-cascader-menu {
  min-height: 180px;
  height: auto;
  max-height: 380px;
}

.AntdCascader:focus {
  .ant-cascader-input {
    border-color: $colorPrimary;
    box-shadow: $box-shadow-base;
  }
}

.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: $colorPrimary;
}

.ant-cascader-input {
  min-width: $inputWidth;
}

.ant-cascader-input:hover {
  border-color: $colorPrimary;
}

.ant-cascader-input:focus {
  border-color: $colorPrimary;
  box-shadow: $box-shadow-base;
}

.ant-cascader-menu-item:hover {
  background: rgba($colorPrimary, 0.05);
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled) {
  color: $colorPrimary;
}

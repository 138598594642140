@import "~@/assets/sass/_function";

.NumStatis {
  .box {
    display: inline-flex;
    flex-wrap: wrap;
    .item {
      margin-right: 20px;
      margin-bottom: 20px;
      width: 274px;
      height: 127px;
      background: rgba(225, 222, 236, 0.4);
      opacity: 1;
      border-radius: 10px;
      text-align: center;
      color: #333333;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .num {
        font-size: 36px;
        font-weight: bold;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

@import "~@/assets/sass/_function";

.ImagePicker {
  display: flex;
  .image-picker {
    &-tip {
      font-size: 15px;
      // line-height: 32px;
      margin-left: 8px;
      margin-bottom: 15px;
    }

    &-block {
      display: inline-block;
      border-radius: 4px;
      border: dotted 1px #dddddd;
      overflow: hidden;
      &:hover {
        border-color: rgba($color: $info-color, $alpha: 0.5);
        .image-block-wrap {
          display: block;
        }
      }
    }
  }

  .add-image-block {
    position: relative;
    // background-color: #f5f5f5;
    transition: all 0.3s;
    cursor: pointer;
    box-sizing: border-box;
    &_content {
      width: 100%;
      @include center-translate(both);
      top: 55%;

      &-icon {
        display: block;
        font-size: 16px;
      }

      &-tip {
        font-size: 14px;
        margin-top: 7px;
      }
    }

    &:hover {
      .add-image-block_content-icon {
        color: lighten($primary-color, 5%);
      }
    }
  }

  .image-block {
    position: relative;
    box-sizing: border-box;
    &-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      letter-spacing: 2px;
      line-height: 120px;
      color: #dddddd;
      background-color: rgba($color: #000000, $alpha: 0.4);
      user-select: none;
    }
    &-wrap {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #dddddd;
      background-color: rgba($color: #000000, $alpha: 0.4);
      text-align: center;
      .image-block-delete {
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-color: hsla(0, 0%, 100%, 0.2);
        color: #fff;
        cursor: pointer;
        z-index: 1001;
        display: flex;
        align-items: center;
        justify-content: center;
        .wtdc-icon-delete {
          font-size: 12px;
        }
      }
      .image-block-prev {
        width: 100%;
        height: 100%;
        cursor: pointer;
        color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 4px;
      }
    }
  }

  .re-upload-btn {
    display: inline-block;
    vertical-align: text-bottom;
    text-decoration: underline;

    &:hover {
      background-color: #4e50b8 !important;
      color: white !important;
    }
  }

  &:not(.disabled) {
    .add-image-block {
      cursor: pointer;

      &:hover {
        background-color: #fcfcfc;
        .add-image-block_content-icon {
          color: darken($primary-color, 6%);
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

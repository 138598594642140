@import "~@/assets/sass/_function";

.WelcomeMessageEditor-RBXSDDC {
  width: 662px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 1;
  border-radius: 4px;

  .top,
  .bottom,
  .attach-list-item {
    width: 100%;
    height: 33px;
    line-height: 33px;
    padding: 0 17px;
  }

  .top {
    color: $private-domain-color;
    font-size: 14px;
  }

  .mid {
    width: 100%;
    height: 225px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
    }
  }

  .bottom {
    .attachment {
      font-size: 14px;
      color: $private-domain-color;

      &-disabled {
        color: #808080;
      }

      .wtdc-icon {
        margin-right: 8px;
      }
    }
    .count-box {
      color: #cccccc;
      font-size: 14px;
    }
  }

  .f-r-sb-c {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .attach-list {
    &-item {
      border-bottom: 1px solid #dddddd;
      font-size: 14px;
      font-weight: 400;
      color: #3a3446;

      .wtdc-icon-delete1 {
        color: #999;
        cursor: pointer;
        padding: 5px;
        font-size: 16px;
      }
    }
  }

  #uploadImgContent {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}

.attach-popover-2ndx {
  margin-left: 10px;
  .ant-popover-inner {
    border: 1px solid #d6d6e2;
    border-radius: 4px;

    &-content {
      padding: 12px 10px;
    }
  }
  .ant-popover-arrow {
    transform: rotate(45deg) !important;
    bottom: 6px !important;
    background: #fff;
    border-bottom: 1px solid #d6d6e2;
    border-right: 1px solid #d6d6e2;

    &-content {
      display: none;
    }
  }
  .attach-menu {
    .attach-menu-item {
      border-bottom: 1px solid #ddd;
      padding: 0 10px 6px;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
        padding-top: 6px;
        padding-bottom: 0;
      }
      .wtdc-icon {
        margin-right: 17px;
      }
    }
  }
}

.attach-modal-21NCX {
  .wtdc-form-item {
    margin-bottom: 26px !important;
    .wtdc-form-item-label {
      margin-right: 10px;
    }
    .wtdc-form-item-children-wrapper {
      width: 358px;
      .ImagePicker {
        .image-block {
          flex-shrink: 0;

          .image-picker-tip {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }

  .modal-footer {
    text-align: right;
    padding-right: 20px;
  }
}

@import "~@/assets/sass/_function";

.GroupCode-wXweBQEE {
  display: flex;
  flex-direction: column;
  height: 100%;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background-color: #d5d5d5;
    cursor: pointer;
  }

  & > .wtdc-tabs {
    height: 58px;
    overflow: unset;
  }

  .push-record {
    padding: 0 20px;
    height: 100%;
  }

  .table-wrap {
    // flex: 1;
    // width: 100%;
    // overflow: auto !important;

    &.operate-step-visible {
      padding: 0 0 34px 0;
    }

    .selector-th.radio.select-all {
      visibility: hidden;
    }
  }

  .group-active-code {
    position: relative;
    flex: 1;
    padding: 0 20px;
    overflow: hidden;
  }

  .group-code-table {
    .td-code-info {
      width: 400px;
    }
    .code-info {
      display: flex;
      align-items: center;
      width: 300px;
      .code-img {
        width: 60px;
        height: 60px;
      }
      .desc-box {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .desc {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 21px;
          color: $gray3;
          margin-bottom: 10px;
        }
        .time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 14px;
          color: $gray9;
        }
      }
    }
    .relate-group-chat {
      color: #4e50b8;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .push-record-table {
    .td-code-info {
      width: 600px;
    }
    .code-info {
      display: flex;
      align-items: center;
      width: 300px;
      .code-img {
        width: 60px;
        height: 60px;
      }
      .desc-box {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .desc {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 21px;
          color: $gray3;
          margin-bottom: 10px;
        }
        .time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 14px;
          color: $gray9;
        }
      }
    }
    .member-wrapper {
      display: flex;
      flex-wrap: wrap;
      .member-text {
        &:not(:nth-child(3n)) {
          &::after {
            content: ";";
          }
        }
      }
    }
  }

  // .push-record-table {
  // padding: 20px 0;
  // }

  .wtdc-tabs-tab:hover {
    color: $wx-primary-color !important;
  }

  .wtdc-tabs-tab-active {
    color: $wx-primary-color !important;
  }

  .wtdc-tabs-ink-bar {
    background-color: $wx-primary-color;
  }

  .search-group {
    display: inline-block;

    .create-time {
      vertical-align: top;
    }
  }
  .SearchBar-Bk8kXb12 {
    .wtdc-calendar-picker {
      width: 360px !important;
    }
  }
}

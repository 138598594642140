@import "~@/assets/sass/_function";

.GroupLayers-MJE0zx0 {
  .w60 {
    min-width: 60px;
  }
  .modal-content-scroll > div:first-child {
    overflow: initial !important;
  }
  .modal-content {
    padding: 0;
    height: 600px;
    .ant-table-body {
    }
  }

  .select-content {
    padding: 0 20px;
    border: 1px solid #e9e9e9;
    .select-table {
      width: 57%;
      padding: 20px 0 0;
      border-right: 1px solid #e9e9e9;
      .ant-table-placeholder {
        border: none;
      }
      .ant-table-row-cell-break-word {
        padding: 10px;
      }
    }
    .select-user {
      flex: 1;
      padding: 72px 0 0 7px;
      &-head {
        padding: 16px 30px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        color: $gray3;
        background: #f7f7f7;
        white-space: nowrap;
      }
      &-content {
        //align-items: center;
      }
      &-item {
        padding: 26px;
        align-items: center;
        border-bottom: 1px solid #e9e9e9;

        .ic {
          display: inline-flex;
          margin-right: 26px;
          cursor: pointer;
        }
        &-icon {
          flex-shrink: 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 4px;
          background-color: #3f51b5;
          color: $white;
          font-size: 26px;
        }
      }
    }
  }
  .SearchBar-lO454_F {
    .wtdc-pagination {
      .wtdc-pagination-total {
        display: none;
      }
    }
  }
}

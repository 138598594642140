@import "~@/assets/sass/_function";

@mixin switchSize($height: 28px, $min-width: 56px, $btn-size: $height - 4px) {
  min-width: $min-width;
  height: $height;
  border-radius: $height / 2;
  line-height: $height;
  padding-left: $btn-size + 8px;

  &.checked {
    padding: 0 $btn-size + 8px 0 10px;
  }

  &:not(.checked) {
    padding: 0 10px 0 $btn-size + 8px;
  }

  .switch-btn {
    width: $btn-size;
    height: $btn-size;
  }
}

.wtdc-switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: white;
  background-color: #ddd;
  transition: background-color 0.3s;
  cursor: pointer;

  .switch-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 50%;
    box-shadow: 1px 1px 3px 0 rgba(10, 26, 43, 0.29);
    background-color: white;
    transform: translateX(1px);
    transition: left 0.3s, transform 0.3s;
  }

  .wtdc-switch_tip {
  }

  &.checked {
    background-color: $primary-color;
    border-color: $primary-color;
    .switch-btn {
      left: 100%;
      transform: translateX(calc(-1px - 100%));
    }
  }

  &.disabled {
    cursor: not-allowed;

    &.checked {
      .switch-btn {
        background-color: #ccc;
      }
    }

    &:not(.checked) {
      background-color: #ccc;
    }
  }

  &.normal {
    @include switchSize(28px, 56px);
  }

  &.small {
    @include switchSize(15px, 26px, 13px);

    .wtdc-switch_tip {
      display: none;
    }
  }
}

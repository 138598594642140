@import "~@/assets/sass/_function";

.ImagePicker-ha7x7H99 {
  .image-picker {
    &-tip {
      font-size: 15px;
      line-height: 32px;
      margin-bottom: 8px;
    }

    &-block {
      display: inline-block;
      border-radius: 4px;
      border: solid 1px #dddddd;
      overflow: hidden;
    }
  }

  .add-image-block {
    position: relative;
    background-color: #f5f5f5;
    transition: all 0.3s;

    &_content {
      @include center-translate(both);
      top: 55%;

      &-icon {
        display: block;
        font-size: 24px;
      }

      &-tip {
        font-size: 15px;
        margin-top: 10px;
      }
    }

    &:hover {
      .add-image-block_content-icon {
        color: lighten($primary-color, 5%);
      }
    }
  }

  .re-upload-btn {
    display: inline-block;
    vertical-align: text-bottom;
    text-decoration: underline;

    &:hover {
      color: lighten($primary-color, 6%);
    }
  }

  &:not(.disabled) {
    .add-image-block {
      cursor: pointer;

      &:hover {
        background-color: #fcfcfc;
        .add-image-block_content-icon {
          color: darken($primary-color, 6%);
        }
      }
    }
  }
}

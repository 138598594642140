@import "~@/assets/sass/_function";

.WeChatCodeStatistics {
  min-height: 100%;
  padding: 30px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  border-radius: 12px;
  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mb60 {
    margin-bottom: 60px;
  }
  .top {
    display: flex;
    justify-content: flex-start;
    > div {
      display: inline-block;
    }
  }
  .operation {
    color: $primary-color;
    cursor: pointer;
  }
  .ant-table-column-sort {
    background: unset;
  }
  .ant-table-thead {
    th.ant-table-column-sort,
    th.ant-table-column-has-sorters:hover {
      background: #fafafa;
    }
  }
  .ant-table-column-sorter {
    display: none;
  }
  .ant-table-thead th.ant-table-column-has-sorters {
    cursor: unset;
  }
  .tableHead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .export-btn {
      .wtdc-icon-daoru1 {
        transform: rotate(180deg);
      }
    }
  }
  .ant-table-thead > tr > th:first-of-type,
  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 30px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
}

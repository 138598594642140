@import "~@/assets/sass/_function";

.wtdc-modal-modify {
  .wtdc-modal-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .modify-modal-container {
    position: fixed;
    z-index: 5;
    padding: 20px 20px 20px 20px;
    background-color: $white;
    box-shadow: 0 3px 7px 0 rgba($black, 0.1);

    .modify-modal-title {
      position: absolute;
      top: 20px;
      left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #3a3446;
    }

    .ic-shanchuguanbi {
      position: absolute;
      top: 0;
      right: 0;
      color: #80798d;
      padding: 12px 12px 6px 6px;
      svg {
        width: 10px !important;
        height: 10px !important;
      }
    }

    .wtdc-form-item {
      &-label {
        font-size: 14px;
      }
    }
    .wtdc-input-normal {
      width: 160px;
    }
    .wtdc-form-item-label {
      padding-right: 10px;
      padding-left: 0;
    }

    .modify-modal_save-btn {
      display: block;
      margin: 10px 0 0 66px;
      padding: 4px 14px;
      font-size: 14px;
      line-height: 20px;
      background-color: #edf0f2;
      border: solid 1px #dddddd;
      color: #333333;
      border-radius: 4px;
      transition: background-color 0.3s;
      &:hover {
        background-color: lighten($primary-color, 5%);
        color: $white;
      }
    }

    &.HIDDEN {
      overflow: hidden;
    }

    &.SHOW {
      transition: transform 0.15s;
    }
  }
  .wtdc-form-horizontal-layout .wtdc-form-item{
    margin-bottom: 20px;
  }
}

@import "~@/assets/sass/_function";

.EchartsStatis {
  .typeBox {
    .ic {
      color: #ddd;
      margin-left: 10px;
    }
    .ant-radio-button-wrapper-checked .ic {
      color: #fff;
    }
    .ant-radio-button-wrapper {
      border-color: #ddd;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
    .Tooltip {
      vertical-align: baseline;
    }
  }
  .dateBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    height: 32px;
    .dateTitle {
      font-weight: bold;
      display: inline-block;
      margin-right: 20px;
    }
  }
}

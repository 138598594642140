@import "~@/assets/sass/_function";
.OperateStep-7qVdP777 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  // margin-bottom: 35px;

  .step-item {
    display: flex;
    align-items: center;
    .step-item-line {
      width: 120px;
      height: 1px;
      margin: 0 16px;
      background-color: #d9d9d9;
    }
    .step-item-num {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border: 1px solid #d9d9d9;
      background-color: #fff;
      border-radius: 100%;
      text-align: center;
      margin-right: 8px;
    }
    .step-item-title {
      color: #8c8c8c;
      font-size: 16px;
    }

    &.active {
      .step-item-num {
        border: 1px solid #4e50b8;
        background-color: #4e50b8;
        color: #fff;
      }
      .step-item-title {
        color: #4e50b8;
      }
    }
    &.completed {
      .step-item-num {
        color: #4e50b8;
        border: 1px solid #4e50b8;
      }
      .step-item-line {
        background-color: #4e50b8;
      }
    }
  }
}

.AddMemberModal-RBKmPeBB {
  .line {
    width: 1px;
    height: 100%;
    background: #e9e9e9;
    margin: 0 6px;
  }

  .select-btn {
    min-width: unset !important;
  }

  .modal-content {
    height: 600px !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0px 30px 0 !important;

    .wtdc-checkbox.wtdc-checkbox-indeterminate,
    .wtdc-trigger.batchSwitch {
      display: none !important;
    }

    .SearchTable {
      display: flex;
      flex-direction: column;
      .table-wrap {
        height: 0;
        flex: 1;
        overflow: auto !important;
      }
      .WTDCImg-EkIN2Gdd {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .left-check {
        margin-left: -60px;
      }
      .right-member {
        margin-left: 20px;
      }

      .member-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 10px;
        .wtdc-icon {
          color: #cccccc;
        }
        .member-img,
        .wtdc-img-transparent-mask {
          width: 48px;
          height: 48px;
          opacity: 1;
          border-radius: 50%;
          overflow: hidden;
        }

        .desc-box {
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0 0 0 14px;
        }
      }
      .th-hasMemberInfo {
        .th-container {
          margin-left: 20px;
        }
      }
      td.ant-table-selection-column {
        padding: 0 8px !important;
      }
    }

    .left-content {
      width: 450px;
      padding: 20px 0 0;

      .SearchBar {
        margin: 0 !important;
      }
    }

    .right-content {
      padding: 72px 0 0;
      flex: 1;
    }
  }

  .ant-table-body {
    height: 460px;
  }
}

@import "~@/assets/sass/_function";

.TableStatis {
  .modal-content-scroll,
  .modal-content-scroll > div {
    max-height: 850px !important;
  }
  .modal-table-cell {
    padding-bottom: 0;
  }
  .modal-content {
    width: 100%;
    max-height: 800px;
    min-height: 600px;
    // overflow: hidden;
  }
  .typeBox {
    .ic {
      color: #ddd;
      margin-left: 10px;
    }
    .ant-radio-button-wrapper-checked .ic {
      color: #fff;
    }
    .ant-radio-button-wrapper {
      border-color: #ddd;
      &:first-child {
        border-radius: 4px 0 0 4px;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
    .Tooltip {
      vertical-align: baseline;
    }
  }
  .tableHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .export-btn {
      .wtdc-icon-daoru1 {
        transform: rotate(180deg);
      }
    }
  }
  .userName,
  .contactQrcodeName,
  .remark {
    @include ellipsis-lines(1);
    margin: 3px 0;
  }
  .nameBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .name {
      margin: 0 10px;
      @include ellipsis-lines(1);
    }
    .anticon-man {
      color: #2680eb;
    }
    .anticon-woman {
      color: #e64d1f;
    }
  }
  .userNameBox {
    border: 1px solid #dddddd;
    border-radius: 4px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    margin: 3px 0;
    .userName {
      margin-left: 10px;
      @include ellipsis-lines(1);
    }
    .ic-wodezhanghu {
      color: #4e50b8;
    }
  }
  .error {
    color: #e64d1f;
  }
  .sourceChannelCode {
    @include ellipsis-lines(1);
  }
  .tagName {
    padding: 0 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    line-height: 22px;
    display: inline-block;
    margin: 3px 4px 3px 0;
    color: #333333;
  }
  .SearchTable {
    margin-bottom: 30px;
    .wtdc-antd-table {
      .ant-table-body {
        height: 540px;
        max-height: 800px !important;
      }
    }
    .wtdc-pagination-small {
      text-align: center;
      margin-bottom: 0;
    }
    .code-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .code-img {
        width: 60px;
        height: 60px;
      }

      .desc-box {
        margin: 0 0 0 10px;

        .desc {
          //width: 270px;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          margin: 10px 0 0 0;
          font-size: 14px;
          line-height: 14px;
          color: #999999;
        }
      }
    }

    .live-code-categroy,
    .wechat-member {
      font-size: 14px;
      color: #3a3446;
    }
  }
  .ant-empty-normal {
    margin: 100px 0;
  }
  .ant-table table thead th:first-of-type,
  .ant-table table tbody td:first-of-type {
    padding-left: 30px;
  }
}
.tableStatis-tooltip {
  z-index: 10; //显示在弹窗上层
}

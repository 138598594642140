@import "~@/assets/sass/_function";

.layout-container {
  .ProjectDetail {
    border-radius: 12px;
    background-color: white;
    padding: 20px;
    margin-left: 20px;
  }
  // 默认页面样式

  .erp-flex-container {
    border-radius: 12px;
    background-color: white;
  }

  .erp-container__content {
    flex-wrap: nowrap;
    border-radius: 12px;
    background-color: $white;

    .erp-flex-container {
      padding: 20px 20px;
      border-radius: 12px;
      background-color: #fff;
      border: 1px solid #e9e9e9;
    }
  }

  #fixedContentHeader {
    background: white;
  }
}

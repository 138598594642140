@import "~@/assets/sass/_function";

.GroupCodeInfo-OnNP4Ytt {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    background-color: #d5d5d5;
    cursor: pointer;
  }
  .back-button {
    display: flex;
    width: 105px;
    padding-left: 11px;
    padding-right: 11px;
    height: 32px;
    align-items: center;
    background-color: rgba($wx-primary-color, 0.1);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    .back-icon {
      font-size: 11px;
      color: $wx-primary-color;
      transform: rotate(90deg);
    }

    .title-text {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: $wx-primary-color;
    }
  }
  .add-active-code-wrapper {
    padding-bottom: 50px;
    flex: 1;
    .wtdc-form-item-label {
      min-width: 120px;
    }
    .BookLabel {
      height: 32px;
      .wtdc-form-item-label {
        min-width: 120px;
      }
    }
    .invite-wrapper {
      display: flex;
      flex-direction: row;
      .invite-title {
        position: relative;
        height: 20px;
      }
      .invite-content {
        margin-left: 50px;
        .wtdc-form-item{
          display: flex;
          flex-direction: column;
        }
        .wtdc-form-item-label {
          min-width: 70px;
        }
        .EmojiEditor {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 30px;
          }
        }
      }
    }
    .active-code-content {
      .info-title {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 34px;
        color: #3a3446;
        margin-bottom: 30px;
      }

      .active-code-title-wrapper {
        display: flex;
        align-items: flex-end;
        margin-bottom: 34px;

        .wechat-title-wrapper {
          display: flex;
          align-items: flex-end;

          .title {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 34px;
            color: #3a3446;
          }

          .subtitle {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 24px;
            color: $gray9;
            margin-left: 8px;
          }
        }

        .go-create {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: $wx-primary-color;
          margin-left: 20px;
          cursor: pointer;
        }
        .how-create {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: $wx-primary-color;
          margin-left: 20px;
          cursor: pointer;
        }
      }
      .select-list {
        .wtdc-form-item-label{
          min-width: 90px;
          padding-right: 15px;
        }
        .title {
          // min-width: 120px;
        }
      }
    }

    .foot-wrapper {
      display: flex;
      margin-top: 30px;
      margin-left: 115px;
      .save-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #dddddd;
        opacity: 1;
        border-radius: 2px;
        cursor: pointer;
      }

      .save-enter-group-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 138px;
        height: 32px;
        background-color: $private-domain-color;
        opacity: 1;
        border-radius: 2px;
        color: $white;
        margin-left: 20px;
        cursor: pointer;
        &.disable {
          background-color: rgba($private-domain-color, 0.5);
          &:hover {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

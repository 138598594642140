.KeywordReply {
  // padding: 20px 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.padding20 {
    padding-bottom: 20px 0;
  }

  .SearchTable {
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
  }

  .wtdc-calendar-picker {
    width: 360px !important;
  }

  .search-group {
    width: auto !important;
  }

  .search-btn {
    min-width: unset !important;
  }
}

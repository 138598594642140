@charset "UTF-8";
@import "~@/assets/sass/_function";

.MessageEditor {

  // .wtdc-img{
  //   width: 90px !important;

  // }

  .content-wrapper {
    .content-item.clearfix.image-item {
      .wtdc-form-item.link-msg-previewImg {
        margin: 0 !important;
      }
    }

    .app-wrapper {
      display: flex;
      align-items: center;

      .title {
        margin: 0 0 10px !important;
      }
    }

    .app-img {
      width: 60px !important;
      height: 60px !important;
      margin: 0 16px 0 0;
    }

    .title-desc {
      width: 100% !important;
    }

    .link-description,
    .link-msg-previewImg {
      margin: 0 !important;
    }

    .f-r-sb-fs {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .ic {
        font-size: 16px;
      }
    }

    .content-item {
      padding: 20px;
      width: 642px;
      position: relative;
      border: 1px solid #ddd;
      background-color: #fff;

      .group-title {
        font-size: 14px;
        color: $gray3;
      }

      .group-link {
        font-size: 14px;
        color: #2f8efa;
      }

      .emoji-picker_wrapper {
        z-index: 10;
      }

      &.text-item {
        padding: 0;
        width: 642px;
        border: 0;

        .subtitle {
          display: none;
        }
      }

      &.link-item {
        position: relative;
        width: 642px;
        padding: 30px 30px 30px 20px;
        //height: 130px;
        background-color: $white;
        border-radius: 2px;
        border: solid 1px $grayD;

        .FormItem {
          .subtitle {
            width: 77px;
            margin-right: 10px;
            font-size: 14px;
          }

          .form-control {
            padding-right: 70px;
            width: 500px;
          }

          .length-limit {
            right: 10px;
          }

          .ImageUpload {
            width: 90px;
            height: 90px;
            border: 1px solid rgba($grayD, 0.5);

            &.empty {
              border: dashed 1px #dadee5;
            }

            .add-wrapper {
              position: relative;
              text-align: center;
              background-color: #fafafa;
            }

            .ic-add {
              top: 10px;
              color: #5d79ac;
              font-size: 18px;
              font-weight: bold;
              line-height: 23px;
            }

            .add-text {
              position: absolute;
              bottom: 20px;
              left: 3px;
              width: 88px;
              font-size: 14px;

              .Tooltip {
                margin-right: 5px;
                cursor: pointer;
                font-size: 14px;

                .ic-questionfill {
                  right: 2px;
                  top: 6px;
                  font-size: 14px;
                  color: $gray9;

                  &:hover {
                    color: $colorPrimary;
                  }
                }

                .toolbar-cont {
                  transform: translateX(-164px);
                  width: 257px;

                  .inner {
                    max-width: 257px;
                    padding: 15px 18px;

                    .arrow {
                      right: 20px;
                      left: auto;
                    }

                    .cont {
                      text-align: left;
                    }
                  }
                }
              }
            }
          }

          &.form-linkUrl {
            .subtitle {
              display: none;
            }
          }

          &.FormItem-content {
            margin-bottom: 26px;
          }
        }

        .title-desc {
          width: 487px;
          margin-right: 10px;
          vertical-align: top;

          .FormItem {
            .subtitle {
              width: 77px;
            }

            .form-control {
              width: 400px;
            }
          }
        }
      }

      &.app-item {
        .app-wrapper {
          background-color: #f8f8f8;
          padding: 10px;
          width: 360px;

          .Img {
            margin-right: 14px;
          }

          .app-detail {
            width: 260px;

            .title {
              font-size: 14px;
              @include ellipsis-lines(2);
              color: #000;
            }

            .description {
              font-size: 14px;
              color: #666;
              margin-top: 6px;
            }
          }
        }
      }

      .delete-icon {
        position: absolute;
        top: 0;
        left: 100%;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        border-radius: 2px;
        background-color: $grayD;
        color: $white;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba(240, 151, 56, 0.5);
          color: $white;
        }
      }
    }
  }

  .message-type-box {
    padding: 20px;
    border: 1px dashed #ddd;
    width: 642px;
    background-color: #fff;
    border-radius: 2px;

    .tip {
      text-align: center;
      font-size: 15px;
      color: #666;
    }

    .btn-wrapper {
      text-align: left;

      &.text-center {
        text-align: center;
      }

      .add-btn {
        cursor: pointer;
        margin-right: 26px;
        margin-top: 15px;
        width: 98px;
        line-height: 32px;
        text-align: center;
        height: 32px;
        border-radius: 4px;
        color: white;
        font-size: 14px;
        background-color: #4e50b8;
        position: relative;

        &:nth-child(4n) {
          margin-right: 0;
        }

        .ic {
          margin-right: 6px;
        }

        .ic-questionfill {
          margin-right: 0;
        }

        .Tooltip {
          margin-left: 6px;
          position: relative;
          z-index: 2;
        }

        .blob-wapper {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
        }
      }
    }
  }

  .file-modal {
    .label-tip {
      text-align: center;

      .label-icon {
        background-color: $colorPrimaryLight;
        font-size: 30px;
        height: 60px;
        width: 60px;
        line-height: 60px;
        color: white;
        border-radius: 50%;
        margin-top: 30px;
      }

      p {
        margin: 20px 0;
        font-size: 14px;
      }
    }

    .labelSelect-item {
      .hidden {
        display: none;
      }

      .LabelSelect {
        display: block;
        width: 240px;
        margin: auto;

        .label-select-wap {
          position: relative;
          padding: 0;
          width: 100%;
          margin-bottom: 20px;

          &::before {
            content: "*";
            color: red;
            position: absolute;
            top: 8px;
            left: -12px;
          }

          .LabelSelectItem {
            width: 200px;

            .select-menu {
              left: 0;
              width: 100%;

              .search-input {
                .form-control {
                  width: 180px !important;
                }
              }
            }
          }
        }
      }
    }

    .btn-wrapper {
      text-align: center;

      .file-btn {
        display: inline-block;
        width: 94px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background-color: #5d79ac;
        color: white;
        position: relative;

        &.disabled {
          background-color: rgba($colorPrimaryLight, 0.5);
          cursor: not-allowed;
        }

        .BlobUpload {
          position: absolute;
          z-index: 1;
          opacity: 0;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .adviser-modal {
    .showpage-content {
      text-align: center;
      padding-top: 50px;

      .ic-iosbell {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 50px;
        background-color: #5d79ac;
        color: white;
      }

      .info {
        font-size: 14px;
        margin: 30px 0;
      }

      .AgentAndEditorLabel {
        width: 240px;

        .label-select-wap {
          margin-bottom: 30px;

          &::before {
            @extend %inline-block;
            margin-right: 2px;
            content: "*";
            color: red;
            margin-right: 5px;
          }

          &:last-of-type {
            margin-left: 0;

            .label {
              width: 50px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

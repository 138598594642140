@charset "UTF-8";
@import "~@/assets/sass/_function";

.AddAppAndProduct-RBKmPeBB {
  .modal-content {
    //height: 800px;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;

    .wtdc-tabs {
      height: 70px;

      .wtdc-tabs-tab-active {
        color: #4e50b8;
      }

      .wtdc-tabs-tab:hover {
        color: #4e50b8;
      }

      .wtdc-tabs-ink-bar {
        background-color: #4e50b8;
      }
    }

    .SearchTable {
      display: flex;
      flex-direction: column;

      .table-wrap {
        flex: 1;
        height: 0 !important;
      }

      .btn-primary {
        height: 32px;
        line-height: 32px;
      }

      .wtdc-pagination-item.active {
        color: #ffffff !important;
        background-color: #4e50b8 !important;
      }
      &.app-table {
        .batchSwitch {
          overflow: hidden;
        }
      }
      .SearchBar-lO454_F {
        .search-group {
          width: auto;
        }
      }
    }

    .tab-list {
      flex: 1;
      //overflow: scroll;
      .SearchBar-lO454_F {
        margin-bottom: 20px;
      }
    }
  }

  .modal-container {
    .app-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .code-img {
        width: 60px;
        height: 60px;
      }

      .desc-box {
        margin: 0 0 0 10px;

        .desc {
          //width: 270px;
          font-size: 15px;
          font-weight: bold;
          //line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          margin: 3px 0 0 0;
          font-size: 14px;
          line-height: 14px;
          color: #999999;
        }
      }
    }
  }
}

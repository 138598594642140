@import "~@/assets/sass/_function";

.WelcomeMessageEditor {
  width: 642px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 1;
  border-radius: 2px;

  .top,
  .bottom {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
  }

  .top {
    // color: $private-domain-color;
    font-size: 14px;
  }

  .mid {
    width: 100%;
    min-height: 139px;
    border-top: 1px solid #dddddd;

    .wtdc-form-item {
      align-items: center;
      &-label-text {
        &::after {
          display: none;
        }
      }
    }

    .previewImg {
      padding: 10px;
      .wtdc-form-item-label {
        display: none;
      }
    }

    .link-msg-form {
      width: 100%;
      padding: 20px;

      .wtdc-form-item-required {
        min-width: 80px !important;
        line-height: 1em;
      }

      .link-description {
        margin: 0;
      }

      .link-msg-previewImg {
        margin: 0;

        .wtdc-form-item-label,
        .tipAndOther {
          min-width: 80px !important;
        }
      }
    }

    .wxApp-msg-form {
      width: 100%;
      padding: 20px;

      .wtdc-form-item-required {
        width: 110px;
        min-width: 110px !important;
        user-select: none;
      }

      .apple-msg-previewImg {
        margin: 0;
      }
    }
  }

  .f-r-sb-c {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ic {
      font-size: 16px;
    }
  }

  .f-r-sb-fs {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .ic {
      font-size: 16px;
    }
  }

  .f-r-fs-c {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;

    .desc {
      margin: 0 20px 0 0;
    }
  }
}

@import "~@/assets/sass/_function";

.SearchTable {
  padding-bottom: 0;
  //height: 100%;
  // height:calc(100% - 20px);
  .total-select-btn {
    text-decoration: underline;
  }

  .btn-link {
    color: #4e50b8;
    text-decoration: underline;
  }

  .hidden {
    display: none;
  }

  .wtdc-pagination {
    padding: 10px 20px 0 0;
    text-align: right;
  }

  .ant-pagination {
    position: fixed;
    bottom: 0;
    right: 20px;
  }
}
.pagination-container {
  .pagination-box {
    border-left: 1px solid #dddddd;
    color: #666;

    .pagination-item {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-top: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      font-size: 13px;
      line-height: 32px;
      text-align: center;
      color: #666;
      &--hover {
        background-color: rgba(159, 160, 204, 0.7);
        color: $white;
      }
    }
  }
  .pagination-total {
    line-height: 33px;
  }
}

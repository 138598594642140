@import "~@/assets/sass/_function";
@import "~@wtdc/input/sass/input.scss";

$inputPrefixCls: "wtdc-input";
$textareaPrefixCls: "wtdc-textarea";

.#{$inputPrefixCls} {
  &-disabled {
    color: #999 !important;
    background-color: #f5f5f5 !important;
  }

  &-search-button {
    padding: 0 16px !important;
    background-color: #f8f8f8 !important;
  }
  &-affix {
    color: #b3b0ba;
  }
  &-counter {
    &-length {
      color: #b3b0ba;
    }
  }
}

.#{$textareaPrefixCls} {
  textarea {
    resize: none;
  }
  .wtdc-input-counter-length {
    color: #ccc;
    &-darker {
      color: #b3b0ba;
    }
  }
}

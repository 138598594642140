@import "~@/assets/sass/_function";

.TraceHistory-04YVj4T {
  padding: 20px 50px;
  min-height: 200px;
  .trace-item {
    position: relative;
    width: 100%;
    padding: 0 24px 30px;
    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      left: 0;
      border-radius: 12px;
      background-color: $gray6;
      transform: translate(-50%, 100%);
      z-index: 1000;
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      width: 1px;
      height: 90%;
      background: #f6f5f9;
    }
    &:first-child {
      &::before {
        top: -5px;
        width: 12px;
        height: 12px;
        border: 2px solid $white;
        box-shadow: 0 0 1px 2px $colorPrimary;
        background-color: $colorPrimary;
      }
    }
    &-title {
      flex-shrink: 0;
      margin-right: 30px;
      font-weight: 600;
    }
    &-app {
      width: 310px;
      margin-top: 10px;
      padding: 20px 15px 20px;
      border-radius: 4px;
      background: rgba(78, 80, 184, 0.06);
      &-cover {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }
}

@import "~@/assets/sass/_function";
@import "~@wtdc/select/sass/select.scss";

$selectPrefixCls: "wtdc-select";

.#{$selectPrefixCls} {
  &-selection {
    border-radius: 4px !important;
  }

  &-disabled {
    background-color: #f5f5f5;
  }

  &-selection-item {
    color: #3a3346;
  }
  &-selection-placeholder {
    color: $placeholder-color;
  }
  &-multiple {
    z-index: 1;
    .wtdc-select-selection-search-input {
      height: 34px;
      padding-left: 6px !important;
      min-width: 30px;
    }
    .wtdc-select-selection-item {
      line-height: 26px;
      height: 26px;
      padding-top: 0 !important ;
      padding-bottom: 0 !important;
      padding-right: 24px !important;
      padding-left: 5px !important;
      // margin:0!important;
    }
    .wtdc-select-selection-placeholder {
      line-height: 34px;
      padding-left: 0px !important;
    }
    .wtdc-select-selector {
      // height:36px;
      cursor: pointer;
    }
  }
}

@import "~@/assets/sass/_function";

.BlockPage {
  .page-wrap {
    .item-col {
      position: relative;
      &_check {
        position: absolute;
        left: 36px;
        top: 20px;
        .wtdc-checkbox-symbol {
          vertical-align: top;
        }
      }
    }
    &_footer {
      margin-top: 10px;
      height: 32px;
      .wtdc-checkbox {
        margin-right: 25px;
      }
    }
  }
}

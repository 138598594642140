@import "~@/assets/sass/_function";
.live-code-RBKmPe11 {
  height: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  opacity: 1;
  border-radius: 12px;
  .live-code-table {
    padding: 0 20px 20px !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .table-wrap {
      flex: 1;
      width: 100%;
      overflow: auto;
    }
    .code-info {
      display: flex;
      flex-direction: row;
      align-items: center;

      .code-img {
        width: 60px;
        height: 60px;
      }

      .desc-box {
        margin: 0 0 0 10px;

        .desc {
          //width: 270px;
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .time {
          margin: 10px 0 0 0;
          font-size: 14px;
          line-height: 14px;
          color: #999999;
        }
      }
    }

    .live-code-categroy,
    .wechat-member,
    .scanning-and-adding-times {
      font-size: 14px;
      color: #3a3446;
    }
  }
}
.SearchBar-lO454_F {
  .wtdc-calendar-picker {
    width: 360px !important;
  }
}

@import "~@/assets/sass/_function";

.wtdc-modal-confirm {
  .modal-content {
    padding-top: 54px;
    padding-bottom: 50px;
  }

  .confirm-modal-content-icon {
    display: block;
    font-size: 65px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin: auto;
  }

  .confirm-modal-subtitle {
    margin-top: 24px;
    font-size: 15px;
    color: #3a3446;
    text-align: center;
  }

  .confirm-modal-content {
    text-align: center;
    font-size: 15px;
    margin-top: 12px;
  }
}

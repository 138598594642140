@import "~@/assets/sass/_function";

.LimitMaxConfig {
  .wtdc-form-item-label {
    min-width: 100px !important;
    width: 110px;
    padding-right: 10px;
  }
  .wtdc-form-item-label-text::after {
    display: none;
  }

  .reception-wrap {
    .limit-wrap {
      .member-limit-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 14px;
        .tips-text {
          margin-left: 20px;
          margin-right: 14px;
        }
        & > .wtdc-form-item {
          margin-bottom: 0;
          .wtdc-input-number {
            width: 105px;
            &:hover .wtdc-input-number-handler-wrap {
              opacity: 1;
            }
          }
          .wtdc-input-number-handler-wrap {
            opacity: 0;
          }
        }
        & > .member-item {
          // margin-right: 10px;
          height: 32px;
          background: #ebe9f2;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          padding: 0 6px;
          .member-img-wrap {
            width: 22px;
            height: 22px;
            background: #ffffff;
            border-radius: 50%;
            position: relative;
          }
          .member-img {
            width: 12px;
            height: 12px;
            @include center-translate;
          }
          .member-name {
            padding: 0 8px 0 4px;
          }
          .wtdc-icon-delete {
            color: #ccc;
            transition: color 0.3s;
            cursor: pointer;
            &:hover {
              color: darken($color: #ccc, $amount: 20);
            }
          }
        }
      }
    }
  }
}

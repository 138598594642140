@import "~@/assets/sass/_function";

.EnterpriseWeUser {
  .EnterpriseWeUserFilter,
  .EnterpriseWeUserTable {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 20px;
  }
  .EnterpriseWeUserFilter {
    padding-right: 40px;
    .BtnGroup {
      text-align: right;
      // margin-right: 28px;
    }
    .ant-form-horizontal .ant-form-item-label {
      width: 120px;
    }
    .ant-picker-range {
      display: flex !important;
    }
    .ant-form-item {
      display: flex;
      flex-wrap: nowrap;
      .ant-form-item-label {
        flex-shrink: 0;
      }
      .ant-form-item-control {
        flex-shrink: 1;
        max-width: calc(100% - 120px);
      }
      .anticon-close {
        background: #ccc;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        width: 16px;
        height: 16px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background: #404040;
        }
      }
      .ant-select-selection-item-remove {
        width: 18px;
      }
      .ant-input-clear-icon,
      .ant-cascader-picker-clear,
      .ant-select-clear {
        font-size: 15px;
      }
      .ant-input-affix-wrapper {
        padding: 4px 0 4px 11px;
      }
      .ant-input-clear-icon {
        padding: 2px 11px 2px 4px;
        margin: 0;
      }
      .ant-select-multiple .ant-select-selection-item-remove {
        line-height: 20px;
      }
    }
  }

  .EnterpriseWeUserTable {
    .tableHead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .export-btn {
        .wtdc-icon-daoru1 {
          transform: rotate(180deg);
        }
      }
    }
    .userName,
    .contactQrcodeName,
    .remark {
      @include ellipsis-lines(1);
      margin: 3px 0;
    }
    .nameBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .name {
        margin: 0 10px;
        @include ellipsis-lines(1);
      }
      .anticon-man {
        color: #2680eb;
      }
      .anticon-woman {
        color: #e64d1f;
      }
    }
    .userNameBox {
      border: 1px solid #dddddd;
      border-radius: 4px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 10px;
      margin: 3px 0;
      .userName {
        margin-left: 10px;
        @include ellipsis-lines(1);
      }
      .ic-wodezhanghu {
        color: #4e50b8;
      }
    }
    .error {
      color: #e64d1f;
    }
    .sourceChannelCode {
      @include ellipsis-lines(1);
    }
    .tagName {
      padding: 0 10px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      line-height: 22px;
      display: inline-block;
      margin: 3px 4px 3px 0;
      color: #333333;
    }
  }
}

@import "~@/assets/sass/_function";

.RuleContent-xqws212 {
  display: inline-block;
  width: 553px;
  background: rgba(63, 81, 181, 0.06);
  border-radius: 4px;
  padding: 16px 16px 36px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

  .ant-input-number-focused {
    box-shadow: none;
    border-color: rgba(78, 80, 184, 0.5);
  }

  .base-rule {
    .rule-title {
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-right: 10px;
    }
    .comma {
      margin: 0 10px;
    }

    &-desc {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin: 16px 0 30px;
    }
  }

  .upperLimit {
    display: flex;
    margin-top: 25px;

    &-title {
      display: inline-block;
      margin-top: 5px;
    }

    &-form {
      margin-left: 18px;
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          flex-shrink: 0;
          display: inline-block;
          margin-right: 8px;
        }

        .ant-input-number {
          width: 235px;
        }
      }
    }
  }
}

.sop-table-for-keyword {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;

  .SearchBar {
    margin: 0 !important;
  }

  .SearchBar .search-group button {
    height: 32px;
  }

  .table-wrap {
    flex: 1;
    width: 100%;
    overflow: auto !important;

    &.operate-step-visible {
      padding: 0 0 34px 0;
    }

    .selector-th.radio.select-all {
      visibility: hidden;
    }
  }

  .code-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .code-img {
      width: 60px;
      height: 60px;
    }

    .desc-box {
      margin: 0 0 0 10px;

      .desc {
        //width: 270px;
        font-size: 15px;
        font-weight: bold;
        line-height: 15px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .time {
        margin: 10px 0 0 0;
        font-size: 14px;
        line-height: 14px;
        color: #999999;
      }
    }
  }

  .sop-categroy,
  .wechat-member,
  .scanning-and-adding-times {
    font-size: 14px;
    color: #3a3446;
  }
}

.erp-content_scroll-view {
  // position: relative;

  .btn-box {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
  }

  .SearchBar .AddButton {
    margin-top: 0px !important;
  }

  .SearchBar {
    .wtdc-form-item-label {
      line-height: 32px;
    }
  }
}

.no-active-btn {
  color: #fff !important;
  background-color: rgba(159, 160, 204, 0.7) !important;
  border: 1px solid rgba(159, 160, 204, 0.7);
}
.SearchBar-K_MKWL {
  .wtdc-calendar-picker {
    width: 360px !important;
  }
}

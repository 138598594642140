@charset "UTF-8";
@import "~@/assets/sass/_function";

.EmojiEditor {
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 14px;

  .emoji-editor {
    &_body {
      min-height: 100%;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 1.5;
      color: #333;
      text-align: justify;
      word-break: break-word;
      white-space: pre-wrap;
      outline: none;

      .emoji {
        border: 0;
        vertical-align: middle;
      }
    }

    &_operations {
      height: 35px;
      padding: 0 10px 0 0;
      border-top: 1px solid #eee;

      > * {
        display: inline-block;
        vertical-align: middle;
        line-height: 34px;
      }

      .EmojiBtn {
        position: relative;
        user-select: none;
        .emoji-btn {
          display: block;
          width: 34px;
          height: 34px;
          padding: 0 9px;
          line-height: 34px;
          font-size: 16px;
          color: #666;
          transition: color 0.3s;
          cursor: pointer;
          &:hover {
            color: $colorPrimaryLight;
          }
        }
        .emoji-text-wrapper {
          display: none;
        }

        .emoji-picker_wrapper {
          position: absolute;
          left: 0;
          bottom: 0;
          transform: translate(-60px, 100%);
          z-index: 1;
          .arrow {
            position: absolute;
            top: -8px;
            left: 68px;
            box-shadow: 0 -5px 10px 1px rgba(#999, 0.4);
            s,
            i {
              position: absolute;
              left: 0;
            }
            s {
              top: 0;
              @include triangle(top, 8px, rgba(140, 137, 137, 0.28));
            }
            i {
              top: 1px;
              @include triangle(top, 8px, $colorBg);
            }
          }
        }
      }

      .length-limiter {
        color: #ccc;
        &.overflow {
          .length-limiter_current {
            color: $warning-color;
          }
        }
      }
    }
  }

  &.disabled {
    .emoji-editor_body {
      background-color: #f8f8f8;
    }
  }

  .Placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    user-select: none;
    cursor: text;
    color: #999;
  }

  .fadeIn {
    animation: fadeIn 0.3s 1 linear;
  }
}

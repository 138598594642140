@import "~@/assets/sass/_function";

// fix bug
.FixBottom .btn-fix {
  z-index: 99;
}

.pdf-wrapper,
.word-wrapper,
.excel-wrapper,
.ppt-wrapper {
  max-width: 1600px;

  .showpage-content {
    padding: 20px;
  }
}

.pdfview-wrapper {
  width: 100%;
  height: 80vh;

  .pdfview {
    height: 100%;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 2px;
    display: flex;

    &.fullscreen {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 0;
      z-index: 9999;
    }
  }

  .preview {
    height: 100%;
    border-right: 1px solid #ddd;
    position: relative;
    transition: width 0.3s;
    .toggle {
      background: #f8f8f8;
      border: 1px solid #eee;
      width: 20px;
      height: 40px;
      font-size: 16px;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      color: #ccc;
      cursor: pointer;
      z-index: 1;

      &:hover {
        border-color: $colorPrimaryLight;

        .ic-back {
          color: $colorPrimaryLight;
        }
      }
    }

    &.open {
      width: 150px;
      .toggle {
        left: auto;
        right: 0;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }
    &.close {
      width: 36px;
      .toggle {
        right: auto;
        left: 0;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;

        .ic-back {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    ul {
      padding: 20px;

      li {
        text-align: center;
        padding-bottom: 12px;
        cursor: pointer;
        .preview-img {
          height: 100px;
          width: 100px;
          border: 1px solid #eee;
          background-size: 100% 100%;
          border-radius: 2px;
        }

        &.current {
          .preview-img {
            border-color: $colorPrimaryLight;
          }
          p {
            color: $colorPrimaryLight;
            line-height: 24px;
          }
        }
      }
    }
  }

  .viewer {
    flex: 1;
    background: #f8f8f8;
    padding-top: 32px;
    position: relative;
    .viewer-hd {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #ddd;
      padding: 0 20px;
      position: absolute;
      left: 0;
      top: 1px;
      right: 0;
      overflow: hidden;
      .file-meta {
        float: left;
      }

      .actions {
        float: right;
        font-size: 16px;
        color: #999;
        line-height: 24px;

        span {
          margin-left: 30px;
          cursor: pointer;

          &:hover {
            color: $colorPrimaryLight;
          }
        }

        .fullscreen-span {
          height: 24px;
          line-height: 29px;
          padding: 2px 14px;
          background-image: linear-gradient(180deg, #fff 0%, #f8f8f8 100%);
          border: solid 1px #ccc;
          border-radius: 12px;
          font-size: 14px;
          color: #666;
        }

        .disabled,
        .disabled:hover {
          color: #999;
          cursor: not-allowed;
        }
      }

      .file-status {
        width: 100px;
        text-align: center;
        position: absolute;
        left: 50%;
        margin-left: -50px;
      }
    }

    ul {
      width: 80%;
      margin: 0 auto;
      text-align: center;

      li {
        padding-bottom: 20px;
      }
    }

    .origin-img {
      max-width: 100%;
      width: 100%;
    }
  }
}

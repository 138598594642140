@import "~@/assets/sass/_function";

.Tabs {
  height: 32px;
  .tab-item {
    color: #80798d;
    width: 120px;
    height: 32px;
    border: 1px solid #ddd;
    border-right: none;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    transition: 0.3s;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
      border-right: 1px solid #ddd;
    }
    &.active {
      color: white;
      background: $primary-color;
      border-color: $primary-color;
    }
  }
}

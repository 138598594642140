@import "~@/assets/sass/_function";

.SelectMemberItem {
  padding-bottom: 1px;
  margin-bottom: -20px;

  .whole-day-wrap {
    padding-left: 25px;
  }

  .auto-on-off-line {
    padding-left: 25px;
    .config-tip-text {
      width: 685px;
      height: 71px;
      background: #f6f5f9;
      border-radius: 4px;
      display: flex;
      padding-left: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p {
        font-size: 14px;
        line-height: 24px;
        color: #333;
        margin-bottom: 0;
      }
    }

    .work-period-wrap {
      width: 685px;
      // height: 345px;
      margin-bottom: 20px;
      padding: 20px 20px 1px 30px;
      background: #f6f5f9;
      border-radius: 10px;
      position: relative;
      .delete-icon {
        width: 37px;
        height: 37px;
        background: rgba($color: #4e50b8, $alpha: 0.5);
        border-radius: 0px 10px 0px 10px;
        font-size: 14px;
        color: #ffffff;
        line-height: 37px;
        cursor: pointer;
        transition: background 0.3s;
        position: absolute;
        top: 0;
        right: 0;
        &:hover {
          background: rgba($color: #4e50b8, $alpha: 0.6);
        }
      }
    }
  }
}

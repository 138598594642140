.keyword-table {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .SearchBar {
    margin: 0 !important;
  }

  .app-wrapper {
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 10px;
    width: 360px;

    .title {
      margin: 0 0 10px !important;
    }
  }

  .wtdc-img {
    width: 60px;
    height: 60px;
    margin: 0 16px 0 0;
  }

  .SearchBar .search-group button {
    height: 32px;
  }

  .table-wrap {
    flex: 1;
    width: 100%;
    overflow: auto !important;

    &.operate-step-visible {
      padding: 0 0 34px 0;
    }

    .selector-th.radio.select-all {
      visibility: hidden;
    }
    // padding: 0 0 34px 0;
  }

  .wechat-member {
    display: flex;
    align-items: center;

    .edot-box {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      background-color: #d0d0d0;
      margin: 0 0 0 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: hidden;

      .icon {
        font-size: 18px;
        color: #ffffff;
      }
    }

    &:hover {
      .edot-box {
        visibility: visible;
      }
    }
  }

  .sop-categroy,
  .wechat-member,
  .scanning-and-adding-times {
    font-size: 14px;
    color: #3a3446;

    .wtdc-img {
      width: 65px;
      height: 65px;
    }
  }
}

.erp-content_scroll-view {
  // position: relative;

  .btn-box {
    position: absolute;
    bottom: 0px;
    // '    right: 20px;'
    // margin: 0 0 0  170px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    // padding: ;
  }
}
.SearchBar-lO454_F {
  .wtdc-calendar-picker {
    width: 360px !important;
  }
}

@charset "UTF-8";
@import "~@/assets/sass/_function";

.labelLayer-SSV6s8S {
  .modal-footer {
    padding: 10px 20px;
    text-align: right;
  }
  .modal-content {
    min-height: 160px;
    padding: 40px 40px 70px;
    .label-layer-input {
      display: flex;
      align-items: baseline;
      .wtdc-form-item-label-text {
        &::after {
          visibility: hidden;
        }
      }
      .wtdc-form-item-children-wrapper {
        flex: 1;
      }
    }
    .belong-group {
      margin-top: 35px;
      font-size: 15px;
      .wtdc-form-item-label-text {
        visibility: hidden;
      }
    }
  }
  .modal-footer {
    .delete-group {
      float: left;
      font-size: 15px;
      line-height: 32px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
  .transfer-content {
    &-title {
      padding: 18px 20px;
      background: #f2f2f5;
      border-radius: 6px;
    }
  }
  &.TRANSFER-layer {
    .modal-content {
      padding: 17px 30px 40px;
    }
  }
}

.deleteLayer {
  .confirm-modal {
    &-subtitle {
      font-size: 16px;
      font-weight: 600;
    }
    &-content {
      padding: 0 70px;
      font-size: 14px;
    }
  }
}

.GROUP_EDIT-layer,
.GROUP-layer {
  .modal-content-scroll {
    & > div {
      overflow: hidden !important;
      margin: 0 !important;
    }
  }
  .modal-content {
    padding-bottom: 40px;
    .wtdc-form-item {
      .wtdc-form-item-label-text {
        width: 81px;
        display: inline-block;
      }
      .wtdc-input {
        width: 358px !important;
      }
    }

    .label-group-title,
    .label-item-list-item {
      .wtdc-form-item-label-text {
        &::after {
          visibility: visible;
        }
      }
    }

    .label-item-list {
      max-height: 350px;
      overflow-y: auto;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4);
      }

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 10px;
        }

        .wtdc-form-item {
          margin: 0;
        }

        .operation {
          padding-right: 0px;

          .wtdc-icon-tuodongmulupaixu {
            font-size: 15px;
            color: rgba(63, 81, 181, 0.19);
            margin: 0 5px 0 10px;
            cursor: move;
          }

          .wtdc-icon-yichu {
            color: rgba(63, 81, 181, 0.19);
            margin: 0 5px;
            cursor: pointer;
          }
        }
      }
    }

    .ant-btn {
      border: none;
      position: relative;
      width: 128px;
      height: 32px;
      overflow: hidden;
      margin-left: 92px;
      margin-top: 20px;
      color: #3f51b5;

      &::before {
        content: "";
        display: block;
        position: absolute;
        border: 4px dashed #4e50b8;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
      }

      .wtdc-icon-chuangjian {
        font-weight: bold;
        font-size: 12px;
        color: #3f51b5;
        margin-right: 4px;
      }
    }

    .btn-default {
      border-style: dashed;
      margin-left: 92px;
      margin-top: 20px;
    }
  }
}

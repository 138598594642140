/*
 * @Author: Paco
 * @Date: 2018-11-29 14:49:03
 * @LastEditTIme: Paco
 * @Description: 
 */

 .wtdc-page-loading {
   position: relative;
   width: 100%;
   height: 100%;
   background: rgba(255, 255, 255, .5);
 }
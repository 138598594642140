@import "~@/assets/sass/_function";

.MaterialItem {
  float: none;
  display: inline-block !important;
  position: relative;

  &.hide-btn {
    .inner {
      padding-bottom: 0;
    }
  }

  .delete-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: $white;
    background-color: $colorPrimaryLight;
    border-radius: 2px;
    cursor: pointer;
    display: none;
  }

  .inner {
    width: 260px;
    min-height: 200px;
    position: relative;
    //padding: 20px;
    border-radius: $radiusSmall;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding-bottom: 50px;

    > .btn {
      position: absolute;
      @include center-translate(x);
      bottom: 5px;
    }

    .MsgList {
      border: none;
      .border-box {
        &:last-child {
          .subitem {
            border-bottom: 0;
          }
        }
      }
    }
    .subcaption {
      position: relative;
      display: block;
      padding: 20px 20px 15px;
      color: $gray9;
      .border-box {
        padding-bottom: 15px;
        border-bottom: 1px solid $grayD;
      }
      .material-title {
        width: 60%;
        @extend %ellipsis;
        color: $gray3;
      }
    }
  }
  .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1px;
    left: -1px;
    display: block;
    border-radius: 3px;
    border: 1px solid $colorWarning;
    background-color: rgba(0, 0, 0, 0.15);
    box-sizing: content-box;
    z-index: 10;
    .check-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
      display: block;
      width: 60px;
      height: 60px;
      border: 3px solid $white;
      border-radius: 50%;
      background-color: $colorWarning;
      text-align: center;
      color: $white;
      line-height: 55px;
      font-weight: 800;
      .ic-check {
        font-size: 38px;
      }
    }
  }
  @include tablet {
    margin-bottom: 20px;
  }
}

@import "~@/assets/sass/_function";

.template-modal_fe0uuchh {
  .refresh-material {
    display: inline-block;
    margin-bottom: 20px;
  }

  .Tabs {
    margin-bottom: 10px;
  }

  .BlockPage {
    .item-col {
      display: inline-block;
    }
    .wtdc-pagination {
      margin-top: 20px;
      text-align: center;
      .wtdc-pagination-list {
        display: inline-block;
      }
    }
  }
}

@import "~@/assets/sass/_function";

.live-code-footer {
  height: 56px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 0px 0px 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TemplateMakeContent_dFFYq_mm {
  .step {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 12px;
    padding: 20px;

    &-title {
      height: 32px;
      width: fit-content;
      line-height: 32px;
      margin: 0 0 30px 0;
      padding: 0 14px;
      background: #4e50b8;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }

    .wtdc-form {
      margin-left: 30px;

      .wtdc-form-item {
        display: flex;

        .member-list {
          display: flex;
          flex-wrap: wrap;
          width: 700px;
          align-items: center;

          .modify-btn {
            color: #4e77b8;
            font-size: 15px;
            cursor: pointer;
          }
        }

        .single-code-info {
          display: flex;
          align-items: center;
          margin: 0 30px 0 0;
        }
      }

      .wtdc-form-item-label {
        min-width: 120px;
      }
    }
  }

  .custom-form-item {
    .wtdc-form-item {
      margin-bottom: 0;
    }
    .wtdc-switch {
      margin-right: 12px;
    }

    &-content {
      margin-top: 5px;
    }
  }

  .ant-picker-range {
    border-radius: 4px;
    border: 1px solid #dddddd;
    box-shadow: none;
    &.ant-picker-focused {
      border-color: rgba($color: #7276c4, $alpha: 0.5);
    }
  }

  .baseInfo {
    .desc {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
    .sop-categroy {
      .wtdc-select-selection-placeholder {
        height: 36px;
        line-height: 36px;
      }
    }
    .form-item-friend {
      &-open {
        display: flex;
        margin-bottom: 10px;
        .wtdc-form-item {
          .wtdc-form-item-children-wrapper {
            display: flex;
            align-items: center;
          }
        }
      }
      &-open-time {
        .wtdc-form-item-label {
          width: 60px;
          min-width: 60px;
          padding: 0;
          font-size: 14px;
          color: #333333;
        }

        .wtdc-form-item-children-wrapper {
          margin-left: 10px;
          .wtdc-radio-group {
            display: flex;
            flex-direction: column;
            .wtdc-radio-title {
              font-size: 14px;
              color: #333333;
            }
          }
        }
      }
      .select-time-range {
        margin-left: 92px;
        margin-top: 10px;

        .desc {
          margin-bottom: 10px;
        }
      }
    }
    .form-item-watermark {
      .watermark {
        margin-top: 18px;

        .ImageUpload {
          border: 1px dotted #4e50b8;
          border-radius: 4px;
          margin-right: 27px;

          .wrapper {
            .wtdc-icon-shanchuguanbi {
              font-size: 12px;
            }
            .click-prev {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              font-size: 12px;
            }
          }

          .add-tips {
            color: #4e50b8;
            font-size: 14px;
            display: flex;
            flex-direction: column;

            .wtdc-icon-jiahaoyuan {
              margin-bottom: 4px;
            }
          }
        }

        .schematicDiagram {
          width: 187px;
          height: 187px;
          border: 1px dotted #888;
          background-image: url("https://oss.5rs.me/oss/uploadfe/png/5c5f1d724be8052c95f2029569578001.png");
          background-size: contain;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;

          .watermark-icon {
            width: 51px;
            height: 51px;
            background: #d6d6d6;
            border: 1px solid #ffffff;
            opacity: 1;
            border-radius: 0px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .welcomeMsg {
    .tip {
      min-height: 32px;
      background: #ededf8;
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;
      margin-bottom: 30px;
      .wtdc-icon-tishi {
        color: #4e50b8;
        margin: 0 9px 0 11px;
      }
    }
    .body {
      display: flex;
      flex-direction: row;

      .custom-form-item {
        .wtdc-form-item-label {
          width: 140px;
        }

        .wtdc-radio-group {
          line-height: 20px;
        }
      }

      .time-period-list {
        margin-top: 20px;

        &-item {
          padding: 20px;
          background: #f6f5f9;
          opacity: 1;
          border-radius: 4px;
          margin-bottom: 20px;
          position: relative;

          &:hover {
            .wtdc-icon-shanchuguanbi {
              display: block !important;
            }
          }

          .header {
            margin-bottom: 17px;

            .item-title {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              margin: 0;
            }

            .wtdc-icon-shanchuguanbi {
              position: absolute;
              right: 0;
              top: 0;
              width: 37px;
              height: 37px;
              line-height: 37px;
              background: rgba(78, 80, 184, 0.5);
              border-radius: 0px 6px 0px 6px;
              color: #fff;
              font-weight: bold;
              cursor: pointer;
              display: none;
            }
          }

          .form-item-sendDate {
            margin-bottom: 20px;
            .wtdc-form-item-label {
              width: auto;
              min-width: 0;
              padding-right: 10px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #3a3446;
            }
          }

          .ant-picker-range {
            margin: 0 0 20px 70px;
          }

          .WelcomeMessageEditor {
            width: 642px;
          }
        }

        .addDateBtn {
          border: 1px dotted rgba(78, 80, 184, 1);
          border-radius: 4px;
          .wtdc-icon {
            margin-right: 5px;
          }
        }
      }
    }
    .preview {
      width: 350px;
      margin-left: 90px;

      .main-title {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        color: #333333;
        opacity: 1;
        text-align: center;
      }

      .mainContent {
        width: 100%;
        height: 680px;
        background: url(https://oss.5rs.me/oss/uploadfe/png/601205da859e503b296751431359b5ef.png) center no-repeat;
        background-size: cover;
        position: relative;

        .message-list {
          width: 280px;
          height: 420px;
          padding-top: 10px;
          overflow-y: auto;
          position: absolute;
          left: 40px;
          top: 120px;
          &::-webkit-scrollbar {
            width: 2px;
            height: 5px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.4);
          }

          .message-item {
            display: flex;
            margin-bottom: 16px;

            .avatar-box {
              width: 37px;
              height: 37px;
              background: #4e50b8;
              opacity: 1;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 8px 0 0;
              background-image: url(https://file.5rs.me/oss/uploadfe/png/c01f358be8e7fcb1f5a064de9746760b.png);
              background-position: center;
              background-repeat: no-repeat;
              background-size: 13px 16px;
            }

            .message-comp-text,
            .message-comp-link {
              width: 207px;
              min-height: 52px;
              background: #ffffff;
              opacity: 1;
              border-radius: 4px;
              padding: 13px;
              font-size: 14px;
              font-weight: 400;
              line-height: 26px;
              color: #333333;
              word-break: break-word;
            }

            .message-comp-text {
              white-space: pre-wrap;
            }

            .message-comp-image {
              max-width: 179px;
              opacity: 1;
              border-radius: 4px;
            }

            .message-comp-link {
              &-title {
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 20px;
                color: #3a3446;
                opacity: 1;
              }
              .content {
                display: flex;

                .description {
                  flex: 1;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 18px;
                  color: #666666;
                }

                .WTDCImg-EkIN2Gdd,
                .wtdc-img {
                  width: 35px;
                  height: 35px;
                  overflow: hidden;
                  display: flex;
                  align-items: center;

                  img {
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .f-r-fs-fs {
    display: flex !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

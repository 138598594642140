@import "~@/assets/sass/_function";

.ResourcePreview {
  &.image-wrapper {
    .modal-content {
      text-align: center;
      max-height: 500px;
      .WTDCImg-EkIN2Gdd {
        width: 100%;
        height: 100%;
        text-align: center;
        // display: flex;
        // align-items: center;
        // justify-content: center;
      }
      .wtdc-img {
        height: 100%;
        width: auto;
        img {
          height: 100%;
          width: auto;
        }
      }
      .image-wrapper{
        max-width:460px;
        height:auto;
        .wtdc-img-inner{
          width: 100%;
        }
      }
    }
  }
}

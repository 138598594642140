@import "~@/assets/sass/_function";

.BookLayers-MMnxJwx {
  .modal-container {
    min-width: 1050px;
  }
  .w60 {
    min-width: 60px;
  }
  .wtdc-form-item {
    white-space: nowrap;
  }
  .select-counts {
    background: transparent;
    border: none;
    box-shadow: none;
    pointer-events: none;
  }
  //.book-layers {
  //  &-content {
  //    min-height: 540px;
  //  }
  //}
  .book-class {
    &-label {
      flex-shrink: 0;
    }
  }
}

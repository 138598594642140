.StatisticHome {
  .sop {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .fixed-table {
      width: 100% !important;
    }

    .SearchTable {
      padding: 0 0px 20px !important;
    }
  }

  #fixedContentHeader > div > form > div > button {
    height: 32px !important;
  }

  .SearchBar {
    margin: 0 !important;

    .wtdc-select-selection-placeholder {
      padding: 0 0 0 8px !important;
    }
  }

  #fixedContentHeader .wtdc-calendar-picker {
    width: 360px !important;
  }

  #fixedContentHeader {
    .search-group {
      width: auto !important;
    }
  }
}
.SearchBar-lO454_F {
  .wtdc-calendar-picker {
    width: 360px !important;
  }
}

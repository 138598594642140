@charset "UTF-8";
@import "~@/assets/sass/_function";

.Loading {
  position: relative;
  text-align: center;
  .spinner {
    @include props-dpr(height width margin-left margin-top, 30px 30px -20px 10px);
    display: inline-block;
    left: 50%;
    top: 50%;
    margin-top: 10px;
    border-bottom: 4px solid rgba(165, 162, 156, 0.2);
    border-left: 4px solid rgba(165, 162, 156, 0.2);
    border-right: 4px solid rgba(165, 162, 156, 0.2);
    border-top: 4px solid rgba(165, 162, 156, 1);
    border-radius: 100%;
    animation: rot 0.9s infinite linear;
  }
  @keyframes rot {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
.LoadingRect {
  position: absolute;
  top: 30%;
  left: 30%;
  width: 40%;
  min-width: 200px;
  padding: 35px 10px;
  border-radius: 3px;
  border: 1px solid #999;
  background-color: $white;
  .desc {
    position: absolute;
    top: 10px;
    width: 100%;
    text-align: center;
    padding: 0 0 5px;
    margin: 0;
    font-size: 10px;
    color: #777;
  }
  .spinner {
    position: relative;
    margin: auto;
    width: 80%;
    height: 18px;
    border: 1px solid #ccc;
    white-space: nowrap;
    overflow: hidden;
    @keyframes running {
      0%,
      to {
        width: 30%;
        -webkit-transform: translate3d(-10%, 0, 0);
        transform: translate3d(-10%, 0, 0);
        opacity: 0.8;
      }
      100% {
        width: 100%;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        opacity: 1;
      }
    }
    @keyframes running-end {
      0% {
        left: -100%;
        width: 30%;
        opacity: 1;
      }
      100% {
        left: 0%;
        width: 28%;
        opacity: 0.8;
      }
    }
    .running {
      display: inline-block;
      background-color: #0087b4;
      height: 100%;
      -webkit-animation-name: running;
      animation-name: running;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
    }
    .running-end {
      position: absolute;
      @extend .running;
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      // background-color: rgba(0, 0, 0, .3);
      -webkit-animation-name: running-end;
      animation-name: running-end;
    }
  }
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.Pacman {
  width: 200px;
  height: 90px;
  padding: 20px;
  background-color: rgba($white, 1);
  transform: scale(0.8);
}
@-webkit-keyframes rotate_pacman_half_up {
  0% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@keyframes rotate_pacman_half_up {
  0% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}
@-webkit-keyframes rotate_pacman_half_down {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@keyframes rotate_pacman_half_down {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
@-webkit-keyframes pacman-balls {
  75% {
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-100px, -6.25px);
    transform: translate(-100px, -6.25px);
  }
}
@keyframes pacman-balls {
  75% {
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translate(-100px, -6.25px);
    transform: translate(-100px, -6.25px);
  }
}
.pacman {
  position: relative;
}
.pacman > div:nth-child(2) {
  -webkit-animation: pacman-balls 1s 0s infinite linear;
  animation: pacman-balls 1s 0s infinite linear;
}
.pacman > div:nth-child(3) {
  -webkit-animation: pacman-balls 1s 0.33s infinite linear;
  animation: pacman-balls 1s 0.33s infinite linear;
}
.pacman > div:nth-child(4) {
  -webkit-animation: pacman-balls 1s 0.66s infinite linear;
  animation: pacman-balls 1s 0.66s infinite linear;
}
.pacman > div:nth-child(5) {
  -webkit-animation: pacman-balls 1s 0.99s infinite linear;
  animation: pacman-balls 1s 0.99s infinite linear;
}
.pacman > div:first-of-type {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid rgba($colorPrimary, 0.95);
  border-left: 25px solid rgba($colorPrimary, 0.95);
  border-bottom: 25px solid rgba($colorPrimary, 0.95);
  border-radius: 25px;
  -webkit-animation: rotate_pacman_half_up 0.5s 0s infinite;
  animation: rotate_pacman_half_up 0.5s 0s infinite;
}
.pacman > div:nth-child(2) {
  width: 0px;
  height: 0px;
  border-right: 25px solid transparent;
  border-top: 25px solid rgba($colorPrimary, 0.95);
  border-left: 25px solid rgba($colorPrimary, 0.95);
  border-bottom: 25px solid rgba($colorPrimary, 0.95);
  border-radius: 25px;
  -webkit-animation: rotate_pacman_half_down 0.5s 0s infinite;
  animation: rotate_pacman_half_down 0.5s 0s infinite;
  margin-top: -50px;
}
.pacman > div:nth-child(3),
.pacman > div:nth-child(4),
.pacman > div:nth-child(5),
.pacman > div:nth-child(6) {
  background-color: rgba($colorPrimary, 0.95);
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  width: 10px;
  height: 10px;
  position: absolute;
  -webkit-transform: translate(0, -6.25px);
  -ms-transform: translate(0, -6.25px);
  transform: translate(0, -6.25px);
  top: 25px;
  left: 100px;
}
@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.ball-pulse-sync > div:nth-child(0) {
  -webkit-animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
}
.ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.6s 0.07s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0.07s infinite ease-in-out;
}
.ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.6s 0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0.14s infinite ease-in-out;
}
.ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 0.6s 0.21s infinite ease-in-out;
  animation: ball-pulse-sync 0.6s 0.21s infinite ease-in-out;
}
.ball-pulse-sync > div {
  background-color: $colorPrimary;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

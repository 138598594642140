@import "~@/assets/sass/_function";

.Radio-AcjjvpTT {
  display: flex !important;
  .radioItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 30px;
    .round {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      padding: 4px;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 50%;
      transition: all 0.3s;
      margin-right: 8px;
      .littleRound {
        display: block;
        width: 6px;
        height: 6px;
        background-color: #fff;
        border-radius: 50%;
        transform: scale(0);
        transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
      }
      &.active {
        background-color: #4e50b8;
        border-color: #4e50b8;
        .littleRound {
          transform: scale(1);
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          border: 1px solid $colorPrimaryLight;
          border-radius: 50%;
          animation: zoomFade 0.3s forwards;
          transform-origin: center center;
        }
      }
    }
    &:hover {
      .round {
        border-color: #4e50b8;
      }
    }
  }
}
@keyframes zoomFade {
  from {
    transform: scale(1);
    opacity: 0.8;
  }
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

@charset "UTF-8";
@import "~@/assets/sass/_function";

%between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LabelItem {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
  padding: 26px 20px 10px;
  border: 1px solid $grayD;
  border-radius: 6px;
  .label-tag {
    position: absolute;
    top: -1px;
    left: -1px;
    display: inline-flex;
    padding: 3px 14px;
    background: #ebebeb;
    border-radius: 6px 0px 6px 0px;
  }
  .label-item {
    &-title {
      @extend %between;
      width: 100%;
      margin-bottom: 22px;
    }
    &-name {
      @include ellipsis-lines(1);
    }
  }
  .label-table {
    width: 100%;
    &-head {
      @extend %between;
      padding: 12px 20px;
      background: #f6f5f9;
      border-radius: 4px;
      line-height: 1;
      > span {
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    &-list {
      height: 438px;
      overflow: hidden;
    }
    &-item {
      @extend %between;
      padding: 25px 16px;
      border-bottom: 1px solid #e9e9e9;
      &-cell {
        @include ellipsis-lines(2);
        &:first-child {
          width: 50%;
        }
        &:last-child {
          padding-left: 10px;
          white-space: nowrap;
        }
        .label-cell-btn {
          position: relative;
          padding: 0 4px;
          white-space: nowrap;
          cursor: pointer;
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            content: "";
            display: inline-flex;
            width: 1px;
            height: 12px;
            background-color: $colorPrimary;
            transform: translate(0, -50%);
          }
        }
      }
    }
    &-pagination {
      .wtdc-pagination-list {
        margin: 0;
        line-height: 1;
      }
    }
    &-bottom {
      @extend %between;
      &-icon {
        display: flex;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}

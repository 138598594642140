@import "~@/assets/sass/_function";

.layout-sidebar {
  position: relative;
  width: 60px;
  height: 100vh;
  user-select: none;
  color: #e1deec;
  background-color: $colorPrimary;
  .layout-sidebar-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    bottom: 0;
    background-color: $colorPrimary;
  }
  .logo-img {
    width: 30px;
    height: 30px;
  }

  .sidebar {
    &-header {
      padding: 30px 0 22px;
      white-space: nowrap;

      &__title {
        font-size: 16px;
        line-height: 24px;
        &-wrap {
          white-space: nowrap;
          font-size: 16px;
          font-weight: bolder;
          color: $white;

          &.erp-header {
            cursor: pointer;
          }

          &__logo {
            display: inline-block;
            font-size: 30px;
            margin-bottom: 5px;
          }
        }
      }
    }

    &-permission-list {
      &__item {
        width: 60px;
        height: 50px;
        margin: 0 auto 18px;
        border-radius: 4px;
        font-size: 12px;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        &__icon {
          font-size: 20px;
          margin-top: 2px;
        }

        .unfold-btn {
          position: absolute;
          right: 10px;
          top: 50%;
          font-size: 12px;
          transform: translate(0, -50%) scale(0.7);
        }

        &.active {
          background: #2e2f8e;

          .unfold-btn {
            transform: translate(0, -50%) scale(0.7) rotate(180deg);
          }
        }
      }
    }
  }

  .user-info-wrap {
    position: absolute;
    bottom: 36px;

    .user-intro {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      border-radius: 50%;
    }
    .user-intro-name {
      width: 40px;
      height: 40px;
      background: #b8b9f8;
      border-radius: 50%;
      font-size: 26px;
      line-height: 40px;
      font-weight: bolder;
    }

    .user-info-box {
      position: fixed;
      left: 60px;
      bottom: 32px;
      width: 171px;
      height: 192px;
      background: #fff;
      box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
      opacity: 1;
      border-radius: 4px;
      z-index: 1;
    }
    .user-info-header {
      height: 60px;
      border-bottom: 1px solid #f5f5f5;
    }

    .user-avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      > img {
        border-radius: 50%;
      }

      @include tablet {
        width: 26px;
      }
    }

    .user-name {
      width: 80px;
      font-size: 14px;
      white-space: nowrap;
      @include ellipsis-lines(1);
    }

    .user-info-dropdown {
      width: 100%;
      padding-left: 37px;
      &-item {
        color: #333;
        height: 44px;
        font-size: 14px;
        border-bottom: 1px solid #f5f5f5;
        justify-content: flex-start;
        align-items: center;
        &_icon {
          color: #b6b6b6;
        }

        &:hover {
          color: $primary-color;

          .user-info-dropdown-item_icon {
            color: $primary-color;
          }
        }
      }
    }
  }
}

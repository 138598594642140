@import "~@/assets/sass/_function";

.CodeGether {
  .table {
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 20px;
    .tableHead {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .filter {
        display: flex;
        align-items: center;
        > span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .title-wrap {
    display: flex;
    align-items: center;
    .qrcode-img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}

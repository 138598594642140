@import "~@/assets/sass/_function";

.UserInfo {
  height: 100%;
  .wtdc-img-inner {
    border-radius: 4px;
  }
  .wtdc-tabs-ink-bar {
    background-color: #4e50b8 !important;
  }
  .info-head {
    align-items: center;
    padding: 28px 32px;
    margin-bottom: 20px;
    background: $white;
    border-radius: 12px;
    &-user {
      align-items: center;
      padding-right: 70px;
      &-cover {
        position: relative;
        .wtdc-img-inner {
          border-radius: 50%;
          object-fit: contain;
        }
        .merge-tag {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 2px 5px;
          color: $white;
          font-size: 12px;
          background: #fdae18;
          border-radius: 12px;
          white-space: nowrap;
          transform: translate(0%, 50%);
        }
      }
      &-info {
        color: #3a3446;
        .nickName {
          max-width: 230px;
          @include ellipsis-lines(2);
        }
      }
    }
    &-list {
      flex: 1;
      align-items: center;
      justify-content: space-between;
      padding: 0 70px;
    }

    &-item {
      &-value {
        max-width: 230px;
        font-size: 36px;
        color: $gray3;
        @include ellipsis-lines(1);
      }
    }
    &-operation {
      padding: 0 85px;
      border-left: 1px solid #e9e9e9;
      .cluesName,
      .followStatus {
        display: inline-flex;
        max-width: 120px;
        @include ellipsis-lines(1);
      }
      .empty-operate {
        padding: 8px 10px;
        border-radius: 15px;
        color: $white;
        line-height: 1;
        background: $colorPrimary;
      }
    }
  }
  .info-content {
    flex: 1;
    background: $white;
    border-radius: 12px;
    &-tab {
      display: flex;
      padding: 0 30px;
      .tab-item {
        flex: 1;
        font-size: 16px;
      }
    }
    &-info {
      padding: 30px;
    }
    &-left {
      flex-shrink: 0;
      width: 380px;
      padding: 23px 0;
      border-right: 1px solid #e9e9e9;
      overflow: hidden;

      .info-item {
        margin: 30px 18px;
        &:first-child {
          margin-top: 0;
        }
      }
    }

    &-right {
      flex: 1;
      .wtdc-tabs-nav-container {
        padding: 0 35px;
      }
      .wtdc-tabs-tab {
        padding: 16px 0;
        font-size: 16px;
        &-active {
          font-weight: 600;
          color: #4e50b8;
        }
      }
      .info-content-list {
        flex: 1;
      }
    }
  }
  .info-item {
    overflow: hidden;
    &-title {
      flex-shrink: 0;
      display: inline-flex;
      width: 100px;
      font-weight: 600;
    }
    &-value {
      margin-left: 20px;
    }
    &-texts {
      flex: 1;
      text-align: center;
    }
  }
  .text-underline {
    color: $colorPrimary;
    text-decoration: underline;
    cursor: pointer;
  }
}

.UserInfo-spinning,
.ant-spin-container {
  height: 100%;
}

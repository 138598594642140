@charset "UTF-8";
@import "~@/assets/sass/_function";

//宣传图尺寸
.advertise {
  width: 142px !important;
}

.ImageUpload {
  position: relative;
  display: inline-block;
  border: 1px solid rgba($grayD, 0.5);
  width: 80px;
  height: 80px;
  background-color: $white;

  .img-box {
    position: relative;
    height: 100%;

    .Loading {
      position: relative;
      height: 100%;

      .spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 40px;
        height: 40px;
        margin: auto;
      }
    }

    .file-img {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .add-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-align: center;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .add-tips {
        font-size: 14px;
        line-height: 1.5em;
        color: #333;
      }

      .ic-add {
        display: block;
        font-weight: bold;
        color: $colorWarning;
        transition: all 0.4s ease;
      }
    }

    &:hover .ic-add {
      transform: scale(1.2, 1.2);
    }

    .cover-label {
      position: absolute;
      width: 100%;
      height: 38px;
      line-height: 38px;
      top: 0;
      bottom: 0;
      margin: auto;
      color: $white;
      background-color: $colorPrimary;
      opacity: 0.7;
    }
  }

  .input-file {
    display: none;
  }

  .wrapper {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 30px;
    color: $white;
    background-color: rgba($gray3, 0.5);
    cursor: pointer;
    z-index: 100;

    & > .ic-shanchuguanbi {
      width: 100%;
      height: 30px;
      line-height: 30px;
      position: relative;
      z-index: 10;
    }

    .wrapper-label {
      margin: auto;
      cursor: pointer;
    }

    &.prev-wrap {
      height: 100%;
    }

    .ic-edit,
    .ic-delete {
      position: absolute;
      bottom: 0;
      width: 50%;
      height: 100%;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
    }

    .ic-edit {
      left: 0;
    }

    .ic-delete {
      right: 0;
    }

    .delete-btn {
      position: absolute;
      right: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background-color: rgba(255, 255, 255, 0.2);
      color: $white;
      z-index: 1001;
      cursor: pointer;

      .ic {
        display: block;
        font-size: 14px;
        line-height: 20px;
        svg {
          height: 20px;
          line-height: 20px;
        }
      }
    }

    .click-prev {
      position: relative;
      width: 100%;
      height: 100%;

      > div {
        position: absolute;
        bottom: 10px;
        width: 100%;
      }
    }
  }

  &:hover .wrapper {
    display: block;
  }

  &.style2 {
    .img-box {
      .Loading {
        .spinner {
          width: 30px;
          height: 30px;
        }
      }
    }

    .wrapper {
      height: 100%;

      .ic-edit {
        width: 100%;
        height: 100%;

        &:before {
          height: 30px;
          @extend %f24;
          @extend %center-translate;
        }
      }

      .ic-delete {
        width: 16px;
        height: 16px;
        font-size: 12px;
        line-height: 18px;
        background-color: $colorPrimary;
        border-radius: 50%;
        color: $white;
        position: absolute;
        right: -8px;
        top: -8px;

        &:before {
          content: "\e601";
        }
      }
    }
  }

  &.empty {
    border: 1px dashed $grayD;

    .wrapper {
      display: none;
    }

    &:hover {
      border: dashed 1px $colorPrimary;
      color: $colorPrimary;
      .img-box .add-wrapper,
      .img-box .add-wrapper .add-tips {
        color: $colorPrimary;
      }
    }

    &.style2 {
      border: none;
      background-color: #eaeef2;
      border-radius: $radiusSmall;

      .ic-add {
        bottom: 10%;
        width: 55%;
        height: 55%;
        background: url(../../assets/images/image.png) no-repeat center center;
        background-size: 100%;

        &::before {
          content: "";
        }
      }

      .size {
        position: absolute;
        width: 100%;
        bottom: 3%;
        font-size: 12px;
        color: #aaa;
      }

      &:hover .ic-add {
        transform: scale(1, 1);
      }
    }
  }
}

@charset "UTF-8";
@import "~@/assets/sass/_function";

.emoji-editor_display-panel {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  word-break: break-all;
  color: #333;
}

@import "~@/assets/sass/_function";
@import "~@wtdc/pagination/sass/pagination.scss";

$paginationPrefixCls: "wtdc-pagination";

.#{$paginationPrefixCls} {
  &-normal {
    .wtdc-pagination-item.active {
      color: #fff !important;
      background-color: rgba(159, 160, 204, 0.7) !important;
    }
  }
  &.style2 {
    text-align: center !important;
    left: 0 !important;
    right: 0;
    transform: translate3d(0, 0, 0) !important;
  }
}

.bigPagination {
  .wtdc-pagination-total,
  .wtdc-pagination-item,
  .wtdc-pagination-quick {
    display: none !important;
  }
}

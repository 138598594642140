@import "~@/assets/sass/_function";

.layout-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 30px;
  height: $page-header-height;
  line-height: 60px;
  font-size: 16px;
  background-color: $white;
  border-radius: 30px 0 0 0;
  transition: width, left 0.3s;
  min-width: 1349px;
  @include tablet {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }

  .ant-tabs {
    flex: 1;
    height: 56px;
    .ant-tabs-bar {
      margin: 0;
      border: 0;
    }
    .ant-tabs-tab {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 56px !important;
      padding: 12px 16px;
      line-height: 1;
      font-size: 16px;
    }
  }

  .header-tips {
    padding-right: 20px;
    font-size: 14px;
    color: #333;
    &-icon {
      color: $colorPrimary;
    }
  }

  .book-head {
    padding-right: 40px;
    padding-top: 5px;
  }
}

@import "~@/assets/sass/_function";

.AddTagModal-RBXSDDC {
  .modal-footer {
    padding: 10px 20px;
    text-align: right;
    .confirm-btn {
      margin-left: 23px;
    }
  }
}

@import "~@/assets/sass/_function";
@import "~@wtdc/radio/sass/radio";

$radioPrefixCls: "wtdc-radio";

.#{$radioPrefixCls} {
  position: relative;
  cursor: pointer;
  line-height: unset;

  input[type="radio"] {
    display: none;
  }

  &-symbol {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    padding: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s;

    .icon {
      display: block;
      width: 6px;
      height: 6px;
      background-color: white;
      border-radius: 50%;
      transform: scale(0);
      transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    }
  }

  &-title {
    margin-left: 10px;
    font-size: 15px;
    display: inline-block;
    vertical-align: middle;
    color: #3a3346;
  }

  &-checked {
    .#{$radioPrefixCls}-symbol {
      background-color: $primary-color;
      border: 1px solid $primary-color;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        border-radius: 50%;
        animation: zoomFade 0.3s forwards;
        transform-origin: center center;
      }

      .icon {
        transform: scale(1);
      }
    }
  }

  &-disabled {
    cursor: not-allowed;

    .#{$radioPrefixCls}-symbol {
      background: #eee !important;
      border-color: #ccc !important;
      color: #999 !important;

      &:after {
        display: none !important;
      }

      .icon {
        background: #ccc !important;
      }
    }

    .#{$radioPrefixCls}-title {
      color: #999;
    }
  }

  &:hover {
    .#{$radioPrefixCls}-symbol {
      border-color: $primary-color;
    }
  }

  &-group {
    display: inline-block;

    .#{$radioPrefixCls} {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@keyframes zoomFade {
  from {
    transform: scale(1);
    opacity: 0.8;
  }
  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

@import "~@/assets/sass/_function";

.WeChatHome {
  .weChat-search {
    &-tip {
      padding: 10px 15px;
      background: #ededf8;
      border-radius: 4px;
      line-height: 1;
    }
    &-form {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &-item {
      display: flex;
      align-items: center;
      margin: 0 15px 15px;
      .wtdc-form-item-label {
        width: 85px;
        white-space: nowrap;
      }
    }
  }
  .user-info {
    align-items: center;
  }
  .message {
    &-text {
      @include ellipsis-lines(3);
    }
    &-link {
      > span {
        @include ellipsis-lines(2);
      }
    }
  }

  .message-template-wrap {
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    padding: 4px;
    background: #ffffff;
    width: 240px;
    .title-text {
      font-size: 12px;
      // padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid $grayD;
    }
    .first-wrap {
      .subitem-hot {
        display: block;
        position: relative;
        color: #464646;
        .shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          opacity: 0.5;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0, transparent 47%, transparent 0, transparent);
        }
        .WTDCImg-EkIN2Gdd {
          height: 102px;
        }
        .first-img {
          height: 100%;
          object-fit: cover;
        }
        h5 {
          font-size: 12px;
        }
        &.multi {
          h5 {
            width: 90%;
            position: absolute;
            left: 15px;
            bottom: 0;
            z-index: 8;
            color: $white;
            color: #ffffff;
            font-size: 14px;
            vertical-align: middle;
          }
          p {
            display: none;
          }
        }
      }
    }
  }
}

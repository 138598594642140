@import "~@/assets/sass/_function";

.batchEditClass_fece43ff {
  .modal-content {
    padding: 30px 45px 30px 30px;
    min-height: 366px;
    .wtdc-form-item {
      display: flex;
      align-items: baseline;
      &-children-wrapper {
        flex: 1;
      }
    }
    .wtdc-select-selection-search-input {
      min-width: 0 !important;
    }
  }
}

@import "~@/assets/sass/_function";
.SelectRelateGroupModal-RBKmPeBB {
  .line {
    width: 1px;
    height: 100%;
    background: #e9e9e9;
    margin: 0 6px;
  }

  .select-btn {
    min-width: unset !important;
  }

  .modal-content {
    height: 600px !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 0px 30px 0 !important;

    .wtdc-checkbox.wtdc-checkbox-indeterminate,
    .wtdc-trigger.batchSwitch {
      display: none !important;
    }

    .SearchTable {
      display: flex;
      flex-direction: column;

      .table-wrap {
        height: 0;
        flex: 1;
        overflow: auto !important;
      }

      .WTDCImg-EkIN2Gdd {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .th-hasGroupChatInfo{
        .th-container{
          margin-left: 20px;
        }
      }
      .group-chat-info-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 20px;
        .wtdc-icon{
          color: #CCCCCC;
        }
      }
      .left-check{
        margin-left: -70px;
      }
      .group-chat-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .group-img-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          background-color: $primary-color;
          border-radius: 2px;
          .member-img {
            width: 30px;
            height: 23px;
          }
        }

        .desc-box {
          .group-name {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #3a3446;
          }
          .group-count {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: $gray9;
            margin-top: 10px;
          }
        }

        .wtdc-icon {
          color: #cccccc;
        }

        .desc-box {
          font-size: 15px;
          font-weight: bold;
          line-height: 15px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0 0 0 14px;
        }
      }
    }

    .left-content {
      width: 450px;
      padding: 20px 0 0;

      .SearchBar {
        margin: 0 !important;
      }
    }

    .right-content {
      padding: 72px 0 0;
      flex: 1;
    }
  }
}

@import "~@/assets/sass/_function";

.SelectBookLabel-i9Lnkkii {
  display: inline-block;
  position: relative;
  vertical-align: top;
  .wtdc-form-item {
    margin-bottom: 0;
  }
  .wtdc-select-selector {
    border-radius: 0 4px 4px 0;
  }
  .booklabel--wrapper {
    display: flex;
  }
  .booklabel--title {
    width: 110px;
  }
  .booklabel--select {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .booklabel__item--wrapper {
    display: inline-flex;
    margin-right: 10px;
    margin-bottom: 0 !important;
  }
  .booklabel__item--title {
    height: 32px;
    line-height: 30px;
    width: 55px;
    text-align: center;
    border: 1px solid #ddd;
    border-right: 0;
    font-size: 14px;
    vertical-align: middle;
    background-color: #f7f7f7;
    border-radius: 4px 0 0 4px;
  }
}

@import "~@/assets/sass/_function";

.AddAggregatePage {
  min-height: 100%;
  padding: 30px 0 30px 30px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  border-radius: 12px;

  & > .form-page {
    display: inline-block;
    width: 720px;
  }

  & > .preview-wrap {
    display: inline-block;
    vertical-align: top;
    // .preview-title {
    //   height: 20px;
    //   font-size: 14px;
    //   font-family: Source Han Sans CN;
    //   font-weight: 400;
    //   line-height: 36px;
    //   color: #333333;
    // }
    // .iphone-wrap {
    //   width: 349px;
    //   height: 680px;
    //   background: center center / 100% 100% no-repeat;
    //   background-image: url(https://oss.5rs.me/oss/uploadfe/png/9202b93c815ca3a0627a38a52ab881e8.png);
    //   padding: 120px 34px;
    // }
  }

  .switch-form-wrapper {
    height: 32px;
    margin-bottom: 20px;
    align-items: center;

    .wtdc-form-item {
      margin-bottom: 0 !important;

      .wtdc-form-item-children-wrapper {
        line-height: 30px;
      }
    }

    .tip {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
}

@charset "UTF-8";
@import "~@/assets/sass/_function";

.EmojiPicker {
  width: 500px;
  height: 185px;
  border-radius: 2px;
  padding: 4px 0 0 6px;
  box-shadow: 0 0 10px 1px rgba(#999, 0.4);
  background-color: white;

  .emoji {
    display: inline-block;
    vertical-align: middle;
    border: 0;
    padding: 10px 10px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 2px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
    &:hover {
      background-color: rgba(#f0f0f0, 0.9);
    }
    &:active {
      background-color: rgba(darken(#f0f0f0, 5%), 0.9);
    }

    > img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
}

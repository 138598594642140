@import "~@/assets/sass/_function";

.MemberList-ZPejOtX {
  padding: 6px 20px;
  .trace-item {
    &-goodsName {
      @include ellipsis-lines(2);
    }
    &-cover {
      border-radius: 4px;
    }
  }
}
